import _ from 'lodash';
import { createStore } from 'state';

import { jobs as api } from 'services/api';
import { Job } from 'models/Job';

let state = (set, get) => ({
	isInitialized: false,
	isLoading: false,
	isUpdating: false,
	hasError: false,
	errorMessage: "",
	hideCompletedItems: false,

	jobs: [],

	initializeStore: async () => {
		set((state) => {
			state.isInitialized = false;
			state.isLoading = true;
			state.itemBeingUpdated = null;
			state.equipmentBeingUpdated = null;
			state.hasError = false;
			state.errorMessage = "";
			state.showCompletedLineItems = true;			
		});

		await get().getJobs();

		set((state) => void (state.isInitialized = true));
	},

	setError: async (message) => {
		set((state) => {
			state.hasError = true;
			state.errorMessage = message;
		});
	},

	clearError: async () => {
		set((state) => {
			state.hasError = false;
			state.errorMessage = "";
		});
	},

	toggleHideCompletedItems: async () => {
		set((state) => {
			state.hideCompletedItems = !get().hideCompletedItems;
			return state;
		});
	},



	getJobs: async () => {
		set((state) => void (state.isLoading = true));

		let result = await api.getJobs();
		let jobs = result.map(record => new Job(record));
		set((state) => {
			state.jobs = jobs;
			
			state.isLoading = false;
			return state;
		});
	},
	
	
	
	updateQuantityOutstanding: async (jobItem, qty) => {
		let jobIndex = get().jobs.findIndex(x => x.job_number == jobItem.job_number);
		if (jobIndex == -1) return;
		let itemIndex = get().jobs[jobIndex].items.findIndex(x => x.item == jobItem.item);
		if (itemIndex == -1) return;
		
		set((state) => {
			state.jobs[jobIndex].items[itemIndex].quantity_outstanding = qty;
			return state;
		});
	},

	updateQuantityShipped: async (jobItem, qty) => {
		let jobIndex = get().jobs.findIndex(x => x.job_number == jobItem.job_number);
		if (jobIndex == -1) return;
		let itemIndex = get().jobs[jobIndex].items.findIndex(x => x.item == jobItem.item);
		if (itemIndex == -1) return;

		set((state) => {
			state.jobs[jobIndex].items[itemIndex].quantity_shipped = qty;
			return state;
		});
	},
	
	updateBinNumber: async (jobStage, binNumber) => {
		let jobIndex = get().jobs.findIndex(x => x.job_number == jobStage.job_number);
		if (jobIndex == -1) return;
		let stageIndex = get().jobs[jobIndex].stages.findIndex(x => x.stage == jobStage.stage);
		if (stageIndex == -1) return;
		
		set((state) => {
			state.jobs[jobIndex].stages[stageIndex].bin_number = binNumber;
			return state;
		});
	},
	
	updateModelNumber: async (jobEquipment, modelNumber) => {
		let jobIndex = get().jobs.findIndex(x => x.job_number == jobEquipment.job_number);
		if (jobIndex == -1) return;
		let stageIndex = get().jobs[jobIndex].stages.findIndex(x => x.stage == jobEquipment.stage);
		if (stageIndex == -1) return;
		let equipmentIndex = get().jobs[jobIndex].stages[stageIndex].equipments.findIndex(x => x.item == jobEquipment.item && x.system_number == jobEquipment.system_number);
		if (equipmentIndex == -1) return;

		set((state) => {
			state.jobs[jobIndex].stages[stageIndex].equipments[equipmentIndex].manufacturer_part_number = modelNumber;
			return state;
		});
	},
	
	updateSerialNumber: async (jobEquipment, serialNumber) => {
		let jobIndex = get().jobs.findIndex(x => x.job_number == jobEquipment.job_number);
		if (jobIndex == -1) return;
		let stageIndex = get().jobs[jobIndex].stages.findIndex(x => x.stage == jobEquipment.stage);
		if (stageIndex == -1) return;
		let equipmentIndex = get().jobs[jobIndex].stages[stageIndex].equipments.findIndex(x => x.item == jobEquipment.item && x.system_number == jobEquipment.system_number);
		if (equipmentIndex == -1) return;

		set((state) => {
			state.jobs[jobIndex].stages[stageIndex].equipments[equipmentIndex].serial_number = serialNumber;
			return state;
		});
	},



	savePullCounts: async (job) => {
		set((state) => {
			state.isUpdating = true;
			return state;
		});
		
		let items = job.items.filter(x => x.quantity_outstanding != 0).map(x => x.toPlain());
		items.forEach(item => {
			item.quantity_shipped += item.quantity_outstanding;
		});

		let payload = {
			items: items
		};

		let result = await api.savePullCounts(job.job_number, payload);
		let updatedJob = new Job(result);

		set((state) => {
			let jobIndex = state.jobs.findIndex(x => x.job_number == updatedJob.job_number);
			state.jobs[jobIndex] = updatedJob;

			state.isUpdating = false;
			return state;
		});
	},
	
	saveBinNumberChanges: async (jobStage) => {
		set((state) => {
			state.isUpdating = true;
			return state;
		});
		
		let payload = {
			job_number: jobStage.job_number,
			stage: jobStage.stage,
			bin_number: jobStage.bin_number
		};
		
		let result = await api.saveBinNumberChanges(jobStage.job_number, jobStage.stage, payload);
		let updatedJob = new Job(result);
		
		set((state) => {
			let jobIndex = state.jobs.findIndex(x => x.job_number == updatedJob.job_number);
			state.jobs[jobIndex] = updatedJob;
			
			state.isUpdating = false;
			return state;
		});
	},
	
	saveSerialNumberChanges: async (jobStage) => {
		set((state) => {
			state.isUpdating = true;
			return state;
		});
		
		let payload = {
			equipments: jobStage.getDirtyEquipments()
		};
		
		let result = await api.saveSerialNumberChanges(jobStage.job_number, jobStage.stage, payload);
		let updatedJob = new Job(result);
		
		set((state) => {
			let jobIndex = state.jobs.findIndex(x => x.job_number == updatedJob.job_number);
			state.jobs[jobIndex] = updatedJob;
			
			state.isUpdating = false;
			return state;
		});
	}			
});

const useJobStore = createStore(state);

export {
	useJobStore
}