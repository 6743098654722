import { Fragment, useContext } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { AppContext } from 'contexts/App';
import { XIcon } from '@heroicons/react/outline'

export function Sidebar({ title="", children }) {
	const app = useContext(AppContext);
	
	return (
		<div className="sidebar">
			{/* Desktop version (doesn't open and close) */}
			<div className="hidden lg:flex md:w-80 md:flex-col md:fixed md:inset-y-0">
				<div className="flex-1 flex flex-col min-h-0 bg-gray-800">

					{/* Logo */}
					<div className="flex items-center h-16 flex-shrink-0 px-4 bg-gray-800 border-b border-gray-700">
						<img 
							className="h-8 w-8" 
							src="https://tailwindui.com/img/logos/workflow-mark-indigo-500.svg"
							alt="Workflow"
						/>
						
						<h2 className="text-2xl font-semibold text-white pl-2">Chronos</h2>
					</div>
					
					{/* Search and List */}
					<div className="mt-5 flex-1 pb-4 px-4 h-0 overflow-y-auto">
						{title !== "" &&
						<div className="text-center mb-4">
							<h1 className="text-2xl font-semibold text-white">{title}</h1>
						</div>
						}
						
						{children}
					</div>
				</div>
			</div>
		
			{/* Mobile version (opens and closes) */}
			<Transition.Root show={app.sidebarOpen} as={Fragment}>
				<Dialog as="div" className="relative z-40 lg:hidden" onClose={app.setSidebarOpen}>
					<Transition.Child
						as={Fragment}
						enter="transition-opacity ease-linear duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="transition-opacity ease-linear duration-300"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
					</Transition.Child>

					<div className="fixed inset-0 flex z-40">
						<Transition.Child
							as={Fragment}
							enter="transition ease-in-out duration-300 transform"
							enterFrom="-translate-x-full"
							enterTo="translate-x-0"
							leave="transition ease-in-out duration-300 transform"
							leaveFrom="translate-x-0"
							leaveTo="-translate-x-full"
						>
							<Dialog.Panel className="relative flex-1 flex flex-col max-w-xs w-full pb-4 bg-gray-800">
								<Transition.Child
									as={Fragment}
									enter="ease-in-out duration-300"
									enterFrom="opacity-0"
									enterTo="opacity-100"
									leave="ease-in-out duration-300"
									leaveFrom="opacity-100"
									leaveTo="opacity-0"
								>
									<div className="absolute top-0 right-0 -mr-12 mt-1 pt-2">
										<button
											type="button"
											className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
											onClick={() => app.setSidebarOpen(false)}
										>
											<span className="sr-only">Close sidebar</span>
											<XIcon className="h-6 w-6 text-white" aria-hidden="true" />
										</button>
									</div>
								</Transition.Child>
								
								{/* Logo */}
								<div className="flex items-center h-16 flex-shrink-0 px-4 bg-gray-800 border-b border-gray-700">
									<img 
										className="h-8 w-8" 
										src="https://tailwindui.com/img/logos/workflow-mark-indigo-500.svg"
										alt="Workflow"
									/>
									
									<h2 className="text-2xl font-semibold text-white pl-2">Chronos</h2>
								</div>
								
								{/* Search and List */}
								<div className="mt-5 flex-1 pb-4 px-4 h-0 overflow-y-auto">
									{title !== "" &&
									<div className="text-center mb-4">
										<h1 className="text-2xl font-semibold text-white">{title}</h1>
									</div>
									}
									
									{children}
								</div>
							</Dialog.Panel>
						</Transition.Child>
						<div className="flex-shrink-0 w-14" aria-hidden="true">
							{/* Dummy element to force sidebar to shrink to fit close icon */}
						</div>
					</div>
				</Dialog>
			</Transition.Root>
		</div>
	)
}