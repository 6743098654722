import _ from 'lodash';
import { CheckIcon } from '@heroicons/react/solid';
import { AuthService } from 'services/AuthService';
import { ButtonLoadingSpinner } from 'components/common/ButtonLoadingSpinner';
import { Button } from 'components/common/buttons/Button';
import { useSettingStore } from 'stores/setting-store';
import { companies } from 'companies';

export function CompanySettingsHeader({ companyId }) {
	const isSaving = useSettingStore(state => state.isSaving);
	const saveSettings = useSettingStore(state => state.saveSettings);	
	
	const handleSaveSettings = () => {
		saveSettings(companyId);
	}		
	
	let buttonIcon = (isSaving) ? 
		<ButtonLoadingSpinner />
		: <CheckIcon className="-ml-1 mr-2 h-5 w-5 text-white" />
		
	let company = _.find(companies, x => x.id == companyId);
	
	return(
		<div className="lg:flex lg:items-center lg:justify-between mb-4">
			<div className="flex-1 min-w-0">
				<h1 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl">Company: {company?.name}</h1>
			</div>
			
			<div className="mt-5 flex lg:mt-0 lg:ml-4">
				<span className="ml-3">
					<Button variant="solid" color="success" leftIcon={buttonIcon} onClick={handleSaveSettings}>Save Settings</Button>
				</span>
			</div>
		</div>
	);
}