import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import voca from 'voca';
import { SidebarSearch } from 'components/common/SidebarSearch';
import { LoadingSpinner } from 'components/common/LoadingSpinner';
import { usePoStore } from 'stores/po-store.js';
import { AppContext } from 'contexts/App';

export function ReceivePurchaseOrderSidebarContents() {
	const nav = useNavigate();
	const app = useContext(AppContext);
	const poStore = usePoStore((state) => state);
	const [filter, setFilter] = useState("");
	
	const showItem = (po) => {
		let poNumber = po.po_number.toString();
		let vendor = po.vendor_name.toLowerCase();
		let lowerFilter = filter.toLowerCase();
		
		return (poNumber.includes(lowerFilter) || vendor.includes(lowerFilter));
	}
	


	if (!poStore.isInitialized || poStore.isLoading) {
		return <LoadingSpinner />
	}
	
	if (poStore.hasError) {
		return <span>`Error: ${poStore.errorMessage}`</span>
	}
	

	
	return(
		<SidebarSearch>
			<SidebarSearch.FreeTextFilter setFilter={setFilter} />
			
			<SidebarSearch.List>
				{poStore.pos.map((po, index) => {
					return <SidebarSearch.Item 
						key={index}
						title={po.po_number}
						subtitle={voca.titleCase(po.vendor_name)}
						badge={po.incomplete_line_items}
						show={showItem(po)}
						onClick={() => {
							app.setSidebarOpen(false);
							nav(`/receive-po/${po.po_number}`)
						}}
					/>
				})}
			</SidebarSearch.List>
		</SidebarSearch>
	);
}


