import voca from 'voca';
import { HideToggle } from 'components/common/buttons/HideToggle';

export function BinDetailsHeader({ bin, showUncounted, setShowUncounted, showStarted, setShowStarted, showCompleted, setShowCompleted }) {
	let binTitle = `[${bin.bin_id}] ${bin.description}`.trim();

	return(
		<div className="bg-gray-100 sticky top-0 lg:top-16">
			<div className="lg:flex lg:items-center lg:justify-between bg-gray-100">
				<div className="flex-1 min-w-0">
					<h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl">{voca.titleCase(binTitle)}</h2>
				</div>
					
				<div className="mt-2 flex flex-col sm:flex-row lg:mt-0 lg:ml-4">
					<span className="mb-3 md:mb-0">
						<HideToggle label="Show Uncounted" hide={showUncounted} setter={setShowUncounted} />
					</span>
					
					<span className="mb-3 md:mb-0 md:ml-3">
						<HideToggle label="Show Started" hide={showStarted} setter={setShowStarted} />
					</span>
					
					<span className="mb-3 md:mb-0 md:ml-3">
						<HideToggle label="Show Completed" hide={showCompleted} setter={setShowCompleted} />
					</span>
				</div>
			</div>
		</div>
	);
}