import { LoadingSpinner } from 'components/common/LoadingSpinner';
import { useSettingStore } from 'stores';

export function GlobalSettingsScreen() {
	const isInitialized = useSettingStore(state => state.isInitialized);
	const isLoading = useSettingStore(state => state.isLoading);
	const settings = useSettingStore(state => state.settings[0]);

	if (!isInitialized || isLoading) {
		return(
			<div className="flex justify-center items-centered h-screen">
				<LoadingSpinner spinnerColorClass='text-gray-800' textColorClass='text-gray-800' />
			</div>
		);
	}
	
	return(
		<>
			<div>
			
			</div>
		</>
	);
}