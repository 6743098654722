import { createContext, useEffect, useState } from 'react';

const AppContext = createContext(null);

const AppProvider = ({ children }) => {	
	let [sidebarOpen, setSidebarOpen] = useState(false);
	let [mobileMenuOpen, setMobileMenuOpen] = useState(false);
	
	let value = {
		sidebarOpen,
		setSidebarOpen,
		mobileMenuOpen,
		setMobileMenuOpen
	}
	
	return <AppContext.Provider value={value}>{children}</AppContext.Provider>
}

export {
	AppContext,
	AppProvider
}