import axios from 'axios';
import { toast } from 'react-hot-toast';
import { AuthService } from 'services/AuthService';

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

axios.defaults.baseURL = BASE_URL;
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common['Accept'] = 'application/json';

/*
|-----------------------------------------------------------------------------
| API Middleware
|-----------------------------------------------------------------------------
|
| Axios allows for request and response interceptors, which can be used to 
| implement middleware. See https://axios-http.com/docs/interceptors for more
| information
*/
axios.interceptors.request.use(
	(config) => {		
		console.log('request: success', config);
		
		config.headers['Authorization'] = `Bearer ${AuthService?.token?.token}`;
		config.headers['chronos4-company'] = AuthService?.user?.activeCompany?.id;
		
		return config;
	},
	
	(error) => {		
		return Promise.reject(error);
	}
);

axios.interceptors.response.use(
	(response) => {
		console.log('response: success', response);
		
		return response;
	},
	
	(error) => {
		console.warn('response: failure', error);
		
		if (error.response.status === 401 || error.response.status === 403) {
			AuthService.logout().then(() => {
				window.location = '/';
			});
		}
		
		return Promise.reject(error);
	}
);



/*
|-----------------------------------------------------------------------------
| API Endpoints
|-----------------------------------------------------------------------------
|
| TODO - Use interceptors or special handling to capture errors, validate
| response statuses, and strip off 'data' / otherwise return the data the way
| that the app wants. Note that we should do VERY MINIMAL data translaation
| here! The whole point of a BFF is that it only serves one app, so the BFF
| should give me what we need without much fuss. If you need to do a bunch
| of transformation, you should really be updating the BFF instead.
*/

// /auth/
const authenticate = async (email, password) => {
	let request = axios.post('/v1/auth', { email, password});
	
	return request.then(response => response.data);
}

const getUser = async () => {
	let request = axios.get(`${BASE_URL}/v1/auth/user`);
	
	return request.then(response => response.data);
}

const updateUser = async (userId, payload) => {
	let request = axios.put(`${BASE_URL}/v1/auth/user/${userId}`, payload);
	
	return request.then(response => response.data);
}

const auth = {
	authenticate,
	getUser,
	updateUser
}



// /purchase-orders/
const getPurchaseOrders = async () => {
	let request = axios.get('/v1/purchase-orders');
	
	return request.then(response => response.data);
}

const getPurchaseOrder = async (id) => {
	let request = axios.get(`/v1/purchase-orders${id}`);
	
	return request.then(response => response.data);
}

const getPoLineItems = async (poNumber) => {
	let request = axios.get(`${BASE_URL}/v1/purchase-orders/${poNumber}/line-items`);
	
	return request.then(response => response.data);
}

const getPoLineItem = async (poNumber, item) => {
	let request = axios.get(`${BASE_URL}/v1/purchase-orders/${poNumber}/line-items/${item}`);
	
	return request.then(response => response.data);
}

const updatePo = async (id, payload) => {
	let request = axios.put(`/v1/purchase-orders/${id}`, payload);
	
	return request.then(response => response.data);
}

const updatePoLineItems = async (poNumber, payload) => {
	let request = axios.put(`${BASE_URL}/v1/purchase-orders/${poNumber}/line-items`, payload);
	
	return request.then(response => response.data);
}

const updatePoLineItem = async (poNumber, item, payload) => {
	let request = axios.put(`${BASE_URL}/v1/purchase-orders/${poNumber}/line-items/${item}`, payload);
	
	return request.then(response => response.data);
}

const notifyIfIncomplete = async (poNumber) => {
	let request = axios.post(`${BASE_URL}/v1/purchase-orders/${poNumber}/notify-if-incomplete`);
	
	return request.then(response => response.data);
}

const purchaseOrders = {
	getPurchaseOrders,
	getPurchaseOrder,
	getPoLineItems,
	getPoLineItem,
	updatePo,
	updatePoLineItems,
	updatePoLineItem,
	notifyIfIncomplete
}



/******************************************************************************
 * 
 * JOB ENDPOINTS
 * 
******************************************************************************/

const getJobs = async () => {
	let request = axios.get('/v1/pull-tracker/jobs');
	
	return request.then(response => response.data);
}

const getJob = async (jobNumber) => {
	let request = axios.get(`/v1/pull-tracker/jobs/${jobNumber}`);
	
	return request.then(response => response.data);
}

const updateJob = async (jobNumber, payload) => {
	let request = axios.put(`/v1/pull-tracker/jobs/${jobNumber}`, payload);
	
	return request.then(response => response.data);
}

const savePullCounts = async (jobNumber, payload) => {
	let request = axios.put(`/v1/pull-tracker/jobs/${jobNumber}`, payload);
	
	toast.promise(request, {
		loading: 'Fetching jobs...',
		success: response => {
			return 'Jobs fetched';
		},
		error: err => {
			return `Error fetching jobs: ${err.toString()}`;
		}
	});

	return request.then(response => response.data);
}

const saveBinNumberChanges = async (jobNumber, stage, payload) => {
	let request = axios.put(`${BASE_URL}/v1/pull-tracker/jobs/${jobNumber}/${stage}/bin`, payload);
	
	return request.then(response => response.data);
}

const saveSerialNumberChanges = async (jobNumber, stage, payload) => {
	let request = axios.put(`${BASE_URL}/v1/pull-tracker/jobs/${jobNumber}/${stage}/serial-numbers`, payload);
	
	return request.then(response => response.data);
}

const getJobItems = async (jobNumber) => {
	let request = axios.put(`/v1/pull-tracker/jobs/${jobNumber}/items`);
	
	return request.then(response => response.data);
}

const getJobEquipment = async (jobNumber) => {
	let request = axios.get(`/v1/pull-tracker/jobs/${jobNumber}/equipment`);
	
	return request.then(response => response.data);
}
	
const jobs = {
	getJobs,
	getJob,
	updateJob,
	savePullCounts,
	saveBinNumberChanges,
	saveSerialNumberChanges,
	getJobItems,
	getJobEquipment
};



/******************************************************************************
 * 
 * INVENTORY ENDPOINTS
 * 
******************************************************************************/

const getWarehouseBins = async () => {	
	let request = axios.get('/v1/inventory/bins');
	
	return request.then(response => response.data);
}

const getWarehouseBin = async (binId) => {
	let request = axios.get(`/v1/inventory/bins/${binId}`);
	
	return request.then(response => response.data);
}

const getWarehouseLocations = async () => {
	let request = axios.get('/v1/inventory/warehouses');
	
	return request.then(response => response.data);
}

const getAllInventoryItems = async () => {
	let request = axios.get(`/v1/inventory/items`);
	
	return request.then(response => response.data);
}

const getInventoryItems = async (binId) => {
	let request = axios.get(`/v1/inventory/bins/${binId}/items`);
	
	return request.then(response => response.data);
}

const getInventoryItem = async (binId) => {
	
}

const updateWarehouseCount = async (binId, partNumber, payload) => {
	let request = axios.post(`${BASE_URL}/v1/inventory/bins/${binId}/items/${partNumber}`, payload);
	
	return request.then(response => response.data);
}

const updateInventoryItemStatus = async (binId, partNumber, payload) => {	
	let request = axios.put(`${BASE_URL}/v1/inventory/bins/${binId}/items/${partNumber}`, payload);
	
	return request.then(response => response.data);
}

const inventory = {
	getWarehouseBins,
	getWarehouseBin,
	getWarehouseLocations,
	getAllInventoryItems,
	getInventoryItems,
	getInventoryItem,
	updateWarehouseCount,
	updateInventoryItemStatus
}




/******************************************************************************
 * 
 * SETTINGS ENDPOINTS
 * 
******************************************************************************/

const getSettings = async () => {
	let request = axios.get('/v1/settings');
	
	return request.then(response => response.data);
}

const saveSettings = async (companyId, payload) => {
	let request = axios.put(`/v1/company/${companyId}/settings`, payload);
	
	return request.then(response => response.data);
}

const settings = {
	getSettings,
	saveSettings
}

export {
	auth,
	purchaseOrders,
	jobs,
	inventory,
	settings
}