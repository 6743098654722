import { Outlet } from 'react-router-dom';
import { AppLayout } from 'components/common/layouts';
import { Sidebar } from 'components/common/Sidebar';
import { MainContent } from 'components/common/MainContent';
import { PullTrackerSidebarContents } from 'components/pull-tracker/PullTrackerSidebarContents';
import { useJobStore } from 'stores/job-store';
import { LoadingSpinner } from 'components/common/LoadingSpinner';

export default function PullTrackerScreen() {	
	const isInitialized = useJobStore((state) => state.isInitialized);
	const initializeStore = useJobStore((state) => state.initializeStore);
	
	if (!isInitialized) {
		initializeStore();
	}

	return(
		<AppLayout>
			<Sidebar title="Job Pull Tracker">
				<PullTrackerSidebarContents />
			</Sidebar>
			
			<MainContent>
				<Outlet />
			</MainContent>
		</AppLayout>
	);
}