import { useCallback, useMemo } from 'react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';

import { LoadingSpinner } from 'components/common/LoadingSpinner';
import { BinDetailsHeader } from 'components/inventory/BinDetailsHeader';
import { BinItemsTable } from 'components/inventory/BinItemsTable';
import { useInventoryItemStore, useWarehouseBinStore } from 'stores';

export function InventoryDetailScreen() {
	let { binId } = useParams();
	
	
	
	/**
	 * Repo setup
	 */
	const items = useInventoryItemStore(
		useCallback(
			(state) => state.inventoryItems.filter(x => x.bin_id == binId), 
			[binId]
		)
	);
	
	const invStoreIsLoading = useInventoryItemStore((state) => state.isLoading);
	const getInventoryItems = useInventoryItemStore((state) => state.getInventoryItems);
	const updateCount = useInventoryItemStore((state) => state.updateCount);
	const visibility = useInventoryItemStore((state) => {
		return {
			showUncounted: state.showUncountedItems,
			setShowUncounted: state.setShowUncountedItems,
			showStarted: state.showStartedItems,
			setShowStarted: state.setShowStartedItems,
			showCompleted: state.showCompletedItems,
			setShowCompleted: state.setShowCompletedItems
		}
	});
	
	const bin = useWarehouseBinStore(
		useCallback(
			(state) => state.warehouseBins.find(x => x.bin_id == binId),
			[binId]
		)
	);
	const locations = useWarehouseBinStore(
		useCallback(
			(state) => state.warehouseLocations,
			[binId]
		)
	);
	const binStoreIsLoading = useWarehouseBinStore((state) => state.isLoading);
	const getWarehouseBins = useWarehouseBinStore((state) => state.getWarehouseBins);
	const getWarehouseLocations = useWarehouseBinStore((state) => state.getWarehouseLocations);
	
	
	
	/**
	 * Local state
	 */
	const [freeTextFilter, setFreeTextFilter] = useState("");
	
	
	
	// If the data we need doesn't already exist, try fetching it
	useEffect(() => {
		if (!bin) {
			getWarehouseBins();
		}
		
		if (!locations || locations.length == 0) {
			getWarehouseLocations();
		}
		
		if (!items || items.length == 0) {
			getInventoryItems(binId);
		}
	}, [binId]);
	
	let isLoading = (
		invStoreIsLoading ||
		binStoreIsLoading
	);



	/**
	 * Data management
	 */
	let filteredItems = useMemo(() => {
		let lowerFilter = freeTextFilter.toLowerCase() | "";
		
		return _.filter(items, (item) => {			
			if (!visibility.showUncounted && item.isUncounted()) {
				return false;
			}
			
			if (!visibility.showStarted && item.isStarted()) {
				return false;
			}
			
			if (!visibility.showCompleted && item.isCompleted()) {
				return false;
			}
			
			if (!lowerFilter) {
				return true;
			}
			
			let name = item.part_number.toLowerCase();
			let description = item.description.toLowerCase();
			let manufacturer = item.manufacturer.toLowerCase();
			let manufacturerPartNumber = item.manufacturer_part_number.toLowerCase();
			
			if (
				!name.includes(lowerFilter) &&
				!description.includes(lowerFilter) &&
				!manufacturer.includes(lowerFilter) &&
				!manufacturerPartNumber.includes(lowerFilter)
			) {
				return false;
			}
			
			return true;
		});
	}, [items, freeTextFilter]);

	if (isLoading) {
		return (
			<div className="flex justify-center items-centered h-screen">
				<LoadingSpinner spinnerColorClass='text-gray-800' textColorClass='text-gray-800' />
			</div>
		);
	}



	/**
	 * Actions
	 */
	const refreshData = () => {
		getWarehouseLocations();
		getInventoryItems(binId);
		setFreeTextFilter("");
	}
	
	const updateItem = () => {
		console.log('update item!');
	}
	
	
	
	return(
		<>
			<BinDetailsHeader 
				bin={bin} 
				showUncounted={visibility.showUncounted} 
				setShowUncounted={visibility.setShowUncounted} 
				showStarted={visibility.showStarted} 
				setShowStarted={visibility.setShowStarted} 
				showCompleted={visibility.showCompleted} 
				setShowCompleted={visibility.setShowCompleted} 
			/>
			
			<BinItemsTable bin={bin} items={filteredItems} />
		</>
	);
}






