import { Outlet } from 'react-router-dom';
import { AppLayout } from 'components/common/layouts';
import { Sidebar } from 'components/common/Sidebar';
import { MainContent } from 'components/common/MainContent';
import { InventorySidebarContents } from 'components/inventory/InventorySidebarContents';
import { useInventoryItemStore, useWarehouseBinStore } from 'stores';

export default function InventoryScreen() {
	const isInitialized = useWarehouseBinStore((state) => state.isInitialized);
	const initializeStore = useWarehouseBinStore((state) => state.initializeStore);
	const inventoryItems = useInventoryItemStore((state) => state.inventoryItems);
	const getAllInventoryItems = useInventoryItemStore((state) => state.getAllInventoryItems);
	
	if (!isInitialized) {
		initializeStore();
	}
	
	const getItems = async () => {
		await getAllInventoryItems();
	}
	
	if (inventoryItems.length === 0) {
		getItems();
	}
	
	
	
	return(
		<AppLayout>
			<Sidebar title="Inventory">
				<InventorySidebarContents />
			</Sidebar>
			
			<MainContent>
				<Outlet />
			</MainContent>
		</AppLayout>
	);
}