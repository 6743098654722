import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import voca from 'voca';
import { SidebarSearch } from 'components/common/SidebarSearch';
import { LoadingSpinner } from 'components/common/LoadingSpinner';
import { useJobStore } from 'stores/job-store.js';
import { AppContext } from 'contexts/App';

export function PullTrackerSidebarContents() {
	const nav = useNavigate();
	const app = useContext(AppContext);
	const jobStore = useJobStore((state) => state);
	const [filter, setFilter] = useState("");
	const [statusFilter, setStatusFilter] = useState({ id: 'all', label: 'All' });

	const statusFilterOptions = [
		{ id: 'all', label: 'All' },
		{ id: 'unprocessed', label: 'Unprocessed' },
		{ id: 'in-process', label: 'In Process' },
		{ id: 'completed', label: 'Completed' }
	];
	
	const showItem = (job) => {
		let jobNumber = job.job_number;
		let address = job.address.toLowerCase();
		let lowerFilter = filter.toLowerCase();
		
		// First test the free text filter
		if (!jobNumber.includes(lowerFilter) && !address.includes(lowerFilter)) {
			return false;
		}
		
		// Now test the dropdown filters
		if (statusFilter.id === 'all') {
			return true;
		}
		
		if (statusFilter.id === 'unprocessed') {
			return job.isUnprocessed();
		}
		
		if (statusFilter.id === 'in-process') {
			return job.isInProcess();
		}
		
		if (statusFilter.id === 'completed') {
			return job.isComplete();
		}
		
		// Not sure how you would ever get down here, but return true just in case
		return true;
	}
	
	if (!jobStore.isInitialized || jobStore.isLoading) {
		return <LoadingSpinner />
	}
		
	if (jobStore.hasError) {
		return <span>`Error: ${jobStore.errorMessage}`</span>;
	}
	
	return(
		<SidebarSearch>
			<div className="w-full mb-8">
				<SidebarSearch.DropdownFilter options={statusFilterOptions} selected={statusFilter} setSelected={setStatusFilter} />
			</div>
			<SidebarSearch.FreeTextFilter setFilter={setFilter} />
			
			<SidebarSearch.List>
				{jobStore.jobs.map((job, index) => {
					return <SidebarSearch.Item
						key={index}
						title={voca.titleCase(job.address)}
						subtitle={job.job_number}
						badge={job.incompleteItemsCount()}
						show={showItem(job)}
						onClick={() => {
							app.setSidebarOpen(false);
							nav(`/pull-tracker/${job.job_number}`)
						}}
					/>
				})}
			</SidebarSearch.List>
		</SidebarSearch>
	);
}



