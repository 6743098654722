/**
 * AuthService Module
 * @module services/AuthService
 */
 
import { auth as authApi } from 'services/api';
import { User } from 'models/User';
 
/**
 * A service singleton that provides authentication and reauthentication
 * methods
 */
class AuthService {
	_token;
	_user;
	
	get token() {
		if (!this._token) {
			let storedToken = JSON.parse(localStorage.getItem('christianson_chronos_token'));
			if (!storedToken) storedToken = null;
			
			this._token = storedToken;
		}
		
		return this._token;
	}
	set token(value) {
		this._token = value;
		
		if (this._token !== null) {
			localStorage.setItem('christianson_chronos_token', JSON.stringify(this._token));
		} else {
			localStorage.removeItem('christianson_chronos_token');
		}
	}
	
	get user() {
		if (!this._user) {
			let storedUser = JSON.parse(localStorage.getItem('christianson_chronos_user'));
			if (!storedUser) storedUser = null;
			
			this._user = (storedUser == null) ? null : new User(storedUser);
		}
		
		return this._user;
	}
	
	set user(value) {
		this._user = (value == null) ? null : new User(value);
		
		if (this._user !== null) {
			localStorage.setItem('christianson_chronos_user', JSON.stringify(this._user.toPlain()));
		} else {
			localStorage.removeItem('christianson_chronos_user');
		}
	}

	hasToken() {
		return (
			this.token &&
			this.token !== null &&
			this.token.token &&
			this.token.expires
		);
	}
	
	tokenIsExpired() {
		if (!this.hasToken()) {
			return true;
		}
		
		let expiryDate = new Date(this.token.expires);
		let now = new Date(Date.now());
		
		return expiryDate <= now;
	}
	
	isAuthenticated() {
		return !this.tokenIsExpired();
	}
	
	hasUser() {
		return this.user !== null;
	}
	
	async login(email, password) {
		let response = await authApi.authenticate(email, password);
		this.token = response;
		
		await this.loadUser();
		
		return this.isAuthenticated();
	}
	
	async loadUser() {
		let userResponse = await authApi.getUser();
		this.user = userResponse;
	}
	
	async updateActiveCompany(newActiveCompanyId) {
		let payload = this.user.toPlain();
		payload.active_company_id = newActiveCompanyId;
		
		let userResponse = await authApi.updateUser(payload.id, payload);
		
		this.user = userResponse;
	}
	
	async logout() {
		this.token = null;
		this.user = null;
		window.localStorage.clear();
	}
}

const singleton = new AuthService();

export {
	singleton as AuthService
}