import { immerable } from 'immer'
import _ from 'lodash';
import { DateTime } from 'luxon';
import { WarehouseCount } from 'models/WarehouseCount';
import { AuthService } from 'services/AuthService';

export class InventoryItem
{
	[immerable] = true;
	
	_original;
	
	part_number;
	bin_id;
	description;
	manufacturer;
	manufacturer_part_number;
	period_begin_stock;
	period_end_stock;
	bought_this_period;
	status;
	
	// Warehouse counts indexed by location code
	counts = {}
	
	constructor(data) {
		this._original = data;
		
		_.assign(this, _.omit(data, ['counts']));
		
		this.counts = _.keyBy(data.counts.map(record => {
			return new WarehouseCount(record);
		}), 'location_code');
		
		
		if (!this.counts.hasOwnProperty("1")) {
				this.counts["1"] = this.createStubCount("1");
			}
			
		if (AuthService.user.activeCompany.id == 1) {
			if (!this.counts.hasOwnProperty("2")) {
				this.counts["2"] = this.createStubCount("2");
			}
		}
	}
	
	isDirty() {
		let isDirty = false;
		
		_.each(this.counts, (count) => {
			if (count.isDirty()) {
				isDirty = true;
			}
		});
		
		return isDirty;
	}
	
	isUncounted() {
		return this.status == 'uncounted';
	}
	
	isStarted() {
		return this.status == 'started';
	}
	
	isCompleted() {
		return this.status == 'completed';
	}
	
	
	
	getTotalCount() {		
		return _.reduce(this.counts, (total, count) => {
			let valueToAdd = (count.quantity_counted == null) ? 0 : count.quantity_counted;
			
			total = total + parseInt(valueToAdd);
			
			return total;
		}, 0) ?? 0;
	}
	
	
	
	
	createStubCount(locationCode) {
		let data = {
			part_number: this.part_number,
			location_code: locationCode,
			location_description: "",
			type_code: "W",
			quantity_counted: 0,
			counted_date: null
		}
		
		return new WarehouseCount(data);
	}
}