import { CheckIcon, XIcon } from '@heroicons/react/solid';
import { TextInput } from 'components/common/forms/TextInput';
import { useJobStore } from 'stores/job-store';
import { ButtonLoadingSpinner } from 'components/common/ButtonLoadingSpinner';
import { Button } from 'components/common/buttons/Button';

export function HVACDetailsHeader({ stage }) {
	const isUpdating = useJobStore(state => state.isUpdating);
	const updateBinNumber = useJobStore(state => state.updateBinNumber);
	const saveBinNumberChanges = useJobStore(state => state.saveBinNumberChanges);
	const saveSerialNumberChanges = useJobStore(state => state.saveSerialNumberChanges);

	const updateBinNumberHandler = (value) => {
		updateBinNumber(stage, value);
	}
	
	const saveBinNumber = () => {
		saveBinNumberChanges(stage);
	}
	
	const saveSerialNumbers = () => {			
		let equipments = stage.getDirtyEquipments();
		
		let allValid = true;
		equipments.forEach(equipment => {
			if ((equipment.manufacturer_part_number && !equipment.serial_number) ||
				(equipment.serial_number && !equipment.manufacturer_part_number)) {
				allValid = false;
			}
		});
		
		if (!allValid) {
			return;
		}
	
		saveSerialNumberChanges(stage);
	}
	
	let binNumberButtonIcon = (isUpdating) ? 
		<ButtonLoadingSpinner />
		: <CheckIcon className="-ml-1 mr-2 h-5 w-5 text-white" />
		
	let serialNumbersButtonIcon = (isUpdating) ? 
		<ButtonLoadingSpinner />
		: <CheckIcon className="-ml-1 mr-2 h-5 w-5 text-white" />
		
	
	const disableBinNumberSave = isUpdating == true || stage.bin_number == null || stage.bin_number == '';
	const disableSerialNumberSave = isUpdating == true || (stage && stage.isEquipmentPulled() == false);	
	
	return(
		<div className="lg:flex lg:items-center lg:justify-between mb-8">
			<div className="flex-1 min-w-0">
				<h2 className="text-2l font-bold leading-7 text-gray-700 sm:text-2xl">{stage.stage_name}</h2>
				
				<div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 items-start sm:items-center">
					<div className="flex flex-col">
						<div className="flex items-center text-sm text-gray-500">
							Material Pulled:
							{stage?.material_pulled &&
								<CheckIcon className="flex-shrink-0 ml-1.5 h-5 w-5 text-green-500 ml-auto" aria-hidden="true" />
							}
							{!stage?.material_pulled &&
								<XIcon className="flex-shrink-0 ml-1.5 h-5 w-5 text-red-500 ml-auto" aria-hidden="true" />
							}
						</div>
						
						<div className="flex items-center text-sm text-gray-500">
							Equipment Pulled:
							{stage?.equipment_pulled &&
								<CheckIcon className="flex-shrink-0 ml-1.5 h-5 w-5 text-green-500" aria-hidden="true" />
							}
							{!stage?.equipment_pulled &&
								<XIcon className="flex-shrink-0 ml-1.5 h-5 w-5 text-red-500" aria-hidden="true" />
							}
						</div>
					</div>
					
					<div className="flex flex-col">
						<div className="flex items-center text-sm text-gray-500 sm:ml-4">
							Systems:
							<div className="flex-shrink-0 ml-1.5 h-5 w-5 text-gray-800">{stage.number_of_systems}</div>
						</div>
						
						<div className="flex items-center text-sm text-gray-500 sm:ml-4">
							Crew #:
							<div className="flex-shrink-0 ml-1.5 h-5 w-5 text-gray-800 ml-auto">{stage.crew_number}</div>
						</div>
					</div>
					
					<div className="flex flex-row items-center justify-center mt-4 md:mt-0 md:ml-8">
						<div className="flex flex-row items-center">
							<div className="text-sm font-medium text-gray-500 whitespace-nowrap mr-2">Bin #</div>
							<TextInput 
								id={stage.job_number} 
								type="text" 
								value={stage.bin_number} 
								changeHandler={updateBinNumberHandler} 
								className={`appearance-none block w-24 px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 
											focus:outline-none focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm text-center font-bold`} 
								onFocus={(e) => e.target.select()} 
								disabled={isUpdating}
							/>
						</div>
						<span className="ml-3">
							<Button variant="solid" color="success" leftIcon={binNumberButtonIcon} onClick={saveBinNumber} disabled={disableBinNumberSave}>Save Bin #</Button>
						</span>
					</div>
					
					<div className="flex flex-row items-center justify-center mt-4 md:mt-0 md:ml-8">
						<Button variant="solid" color="success" leftIcon={serialNumbersButtonIcon} onClick={saveSerialNumbers} disabled={disableSerialNumberSave}>Save Serials</Button>
					</div>
				</div>
			</div>
		</div>
	);	
}