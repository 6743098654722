import _ from 'lodash';
import { createStore } from 'state';

import { purchaseOrders as api } from 'services/api';
import { PurchaseOrder } from 'models/PurchaseOrder';
import { PurchaseOrderLineItem } from 'models/PurchaseOrderLineItem';

let state = (set, get) => ({
	isInitialized: false,
	isLoading: false,
	isUpdatingAllLineItems: false,
	lineItemBeingUpdated: null,
	isNotifying: false,
	hasError: false,
	errorMessage: "",
	hideCompletedLineItems: false,

	pos: [],

	initializeStore: async () => {
		set((state) => {
			state.isInitialized = false;
			state.isLoading = true;
			state.isUpdatingAllLineItems = false;
			state.lineItemBeingUpdated = null;
			state.hasError = false;
			state.errorMessage = "";
			state.showCompletedLineItems = true;
		});

		await get().getPos();

		set((state) => void (state.isInitialized = true));
	},

	setError: async (message) => {
		set((state) => { 
			state.hasError = true;
			state.errorMessage = message;
		});
	},

	removeError: async () => {
		set((state) => {
			state.hasError = false;
			state.errorMessage = "";
		});
	},

	toggleHideCompletedLineItems: async () => {
		set((state) => {
			state.hideCompletedLineItems = !state.hideCompletedLineItems;
		});
	},

	getPos: async () => {
		set((state) => void (state.isLoading = true));

		let result = await api.getPurchaseOrders();
		let pos = result.map(record => new PurchaseOrder(record));
		set((state) => {
			state.pos = pos;

			state.isLoading = false;
		});
	},

	getPo: async (po_number) => {
		set((state) => void (state.isLoading = true));

		let result = await api.getPurchaseOrder(po_number);
		let newPo = new PurchaseOrder(result);
		let index = get().pos.findIndex(x => x.po_number == newPo.po_number);

		set((state) => {
			if (index > -1) {
				state.pos.splice(index, 1, newPo);
			}

			state.isLoading = false;
		});
	},

	updatePo: async (po_number, payload) => {
		set((state) => void (state.isLoading = true));

		let result = await api.updatePo(po_number, payload);
		let newPo = new PurchaseOrder(result);
		let index = get().pos.findIndex(x => x.po_number == po_number);

		set((state) => {
			if (index > -1) {
				state.pos.splice(index, 1, newPo);
			}

			state.isLoading = false;
		});
	},

	updateQuantityToAdd: async (po_number, item, qty) => {
		let poIndex = get().pos.findIndex(x => x.po_number == po_number);
		let po = get().pos[poIndex];
		let liIndex = po.line_items.findIndex(x => x.item == item);

		set((state) => {
			state.pos[poIndex].line_items[liIndex].quantity_to_add = qty;
		});
	},

	receivePoLineItem: async (po_number, item, payload) => {
		set((state) => {
			state.lineItemBeingUpdated = item;
		});

		let result = await api.updatePoLineItem(po_number, item, payload);
		let updatedLineItem = new PurchaseOrderLineItem(result);

		set((state) => {
			let poIndex = state.pos.findIndex(x => x.po_number == po_number);
			if (poIndex >-1) {
				let liIndex = state.pos[poIndex].line_items.findIndex(x => x.item == item);
				if (liIndex > -1) {
					state.pos[poIndex].line_items[liIndex] = updatedLineItem;
				}
			}

			state.lineItemBeingUpdated = null;
		});
	},

	receivePoLineItems: async (po_number, payload) => {
		set((state) => {
			state.isUpdatingAllLineItems = true;
			state.isNotifying = true;
		});

		let result = await api.updatePoLineItems(po_number, payload);
		let updatedPoLineItems = result.map(record => new PurchaseOrderLineItem(record));

		set((state) => {
			let po = state.pos.find(x => x.po_number == po_number);
			
			updatedPoLineItems.forEach(updatedLineItem => {
				let index = po.line_items.findIndex(x => x.item == updatedLineItem.item);
				if (index > -1) {
					po.line_items.splice(index, 1, updatedLineItem);
				}
			});

			state.isUpdatingAllLineItems = false;
			state.isNotifying = false;
		});
	},
	
	notifyIfIncomplete: async (po_number) => {
		set((state) => {
			state.isNotifying = true;
		});
		
		let result = await api.notifyIfIncomplete(po_number);
		
		set((state) => {
			state.isNotifying = false;
		});
	}
});

const usePoStore = createStore(state);

export {
	usePoStore
}