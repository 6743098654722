import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthService } from 'services/AuthService';

export default function LoadingScreen() {	
	const navigate = useNavigate();
	
	// Attempt to restore the user from a stored token while the app loads
	useEffect(() => {
		const continueToApp = () => {
			
			if (AuthService.isAuthenticated() && AuthService.hasUser()) {
				selectInitialRoute();
			} else {
				goToLogin();
			}
		}
		
		const selectInitialRoute = () => {
			// in the future, this may point different types of users to different
			// locations. Or, more likely, set up their initial Company db
			// before routing elsewhere
			
			// for now, just shunt everyone to the dashboard
			navigate('/dashboard', { replace: true });
		}
		
		const goToLogin = () => {
			// call logout to purge the bad token first
			AuthService.logout().then(() => navigate('/login'));
		}
		
		let restoreSession = async () => {
			if (AuthService.isAuthenticated()) {
				try {
					await AuthService.loadUser();
				} catch (error) {
					console.error('[LoadingScreen Bootstrap]', error);
				}
			}
			
			// Adjust the time here if you want the loading screen
			// to show for a fixed period of time for some reason
			setTimeout(continueToApp, 0);
		}
		
		restoreSession();
	}, [navigate]);

	return(
		<div className="relative flex flex-col flex-grow min-h-screen min-w-screen overflow-hidden">
			<div className="text-6xl flex flex-col flex-grow text-center items-center justify-center">
				<svg className="animate-spin-2s h-12 w-12 text-indigo-600 mb-40" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
					<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2"></circle>
					<path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
				</svg>
			</div>
		</div>
	);
}