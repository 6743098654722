import { immerable } from 'immer';
import { DateTime } from 'luxon';

export class WarehouseBin
{
	[immerable] = true;
	
	_original;
	
	bin_id;
	part_number_count;
	description;
	
	constructor(data)
	{
		this._original = data;
		
		this.bin_id = data.bin_id;
		this.part_number_count = data.part_number_count;
		this.description = data.description;
	}
}