import { Fragment, useContext } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { UserIcon, MenuIcon, MenuAlt2Icon, MenuAlt3Icon, XIcon } from '@heroicons/react/outline';
import { AppContext } from 'contexts/App';
import { CompanySelector } from 'components/common/menus/CompanySelector';
import { classNames } from 'helpers';
import { AuthService } from 'services/AuthService';

/**
 * The menu is a big old beast, because there is a lot going on
 * 
 * <Discosure> is from @headlessui/react is a generic name for components that
 * open/close, like accordians and menus that hide on mobile sizes.
 *
 * <Menu>, also from @headlessui/react, handles all sorts of nav elements
 * that have clickable buttons to open/close items
 *
 * <Transition> handles animations for such things (also from @headlessui/react)
 *
 * <NavLink is from react router and is passed in isActive, which is based on 
 * whether the current route matches its "to" attribute.
 *
 * On top of all of the buttons and whatnot, in the mobile version
 * you then need <Disclosure.Panel> items for the mobile menu!
 *
 * The end result is that you have some very weighty components with a lot of
 * attributes that make a tidy menu rendering hard. This is one area where the
 * verbocity of tailwind doesn't help.
 *
 */
function TopMenuItem({ item, mobile=false }) {	
	return(
		<NavLink
			to={item.to}
			className={({ isActive }) => {
				return classNames(
					isActive ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
					`px-3 py-2 rounded-md text-sm font-medium ${mobile} ? block : ''`
				)
			}}
		>
			{item.label}
		</NavLink>
	);
}

export function TopMenu({ children }) {
	const app = useContext(AppContext);
	const navigate = useNavigate();
	
	let signOut = () => {
		AuthService.logout().then(() => navigate('/login'));
	}
	
	const MENU_ITEMS = [
		{ id: 'dashboard', label: 'Dashboard', to: '/dashboard' },
		{ id: 'pull-tracker', label: 'Pull Tracker', to: '/pull-tracker' },
		{ id: 'inventory', label: 'Inventory', to: '/inventory/bins' },
		{ id: 'receive-po', label: 'Receive PO', to: '/receive-po' }
	];
	
	const USER_DROPDOWN_ITEMS = [
		{ id: 'settings', label: 'Settings', onClick: () => navigate('/settings') },
		{ id: 'sign-out', label: 'Sign Out', onClick: signOut }
	];
	
	return(
		<div className="fixed bottom-0 w-full lg:sticky lg:top-0 lg:flex h-16 bg-gray-800 border-b border-gray-700 z-20">
			<div className="lg:hidden flex flex-grow-1 h-16 items-center">
				<button type="button" className="px-4 border-r border-gray-700 text-gray-200 flex flex-grow justify-center items-center h-full" onClick={() => app.setSidebarOpen(true)}>
					LIST
				</button>
			
				<button type="button" className="px-4 border-l border-gray-700 text-gray-200 flex flex-grow justify-center items-center h-full" onClick={() => app.setMobileMenuOpen(true)}>
					MENU
				</button>
			</div>
			
			<div className="max-w-7xl px-4 ml-auto lg:px-2 lg:px-6 hidden lg:block">
				<div className="flex items-center h-16 border-b border-gray-700">
					<div className="flex items-center">								
						<div className="hidden lg:block">
							<div className="flex items-baseline space-x-2 lg:space-x-4">
								{MENU_ITEMS.map(item => (
									<TopMenuItem key={item.id} item={item} />
								))}
							</div>
						</div>
					</div>
					
					<div className="hidden lg:block">
						<div className="ml-4 flex items-center lg:ml-6">
						
							{/* Company selector */}
							<CompanySelector />
						
							{/* Profile dropdown */}
							<Menu as="div" className="ml-3 relative">
								<div>
									<Menu.Button className="max-w-xs bg-gray-800 rounded-full flex items-center text-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
										<span className="sr-only">Open user menu</span>
										<UserIcon className="h-6 w-6" aria-hidden="true" />
									</Menu.Button>
								</div>
								
								<Transition
									as={Fragment}
									enter="transition ease-out duration-100"
									enterFrom="transform opacity-0 scale-95"
									enterTo="transform opacity-100 scale-100"
									leave="transition ease-in duration-75"
									leaveFrom="transform opacity-100 scale-100"
									leaveTo="transform opacity-0 scale-95"
								>
									<Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
										{USER_DROPDOWN_ITEMS.map(item => (
											<Menu.Item key={item.id}>
												{({ active }) => (
													<div 
														onClick={item.onClick}
														className={classNames(
															active ? 'bg-gray-100' : '',
															'block px-4 py-2 text-sm text-gray-700 cursor-pointer'
														)}
													>
														{item.label}
													</div>
												)}
											</Menu.Item>
										))}
									</Menu.Items>
								</Transition>
							</Menu>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}