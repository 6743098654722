import { LoadingSpinner } from 'components/common/LoadingSpinner';
import { useParams } from 'react-router-dom';
import { TextInput } from 'components/common/forms/TextInput';
import { CompanySettingsHeader } from 'components/settings/CompanySettingsHeader';
import { useSettingStore } from 'stores';

export function CompanySettingsScreen() {
	let { companyId } = useParams();
	const isInitialized = useSettingStore(state => state.isInitialized);
	const isLoading = useSettingStore(state => state.isLoading);
	const allSet = useSettingStore(state => state.settings);
	const settings = useSettingStore(state => state.settings[companyId]);
	const updateSetting = useSettingStore(state => state.updateSetting);

	if (!isInitialized || isLoading) {
		return(
			<div className="flex justify-center items-centered h-screen">
				<LoadingSpinner spinnerColorClass='text-gray-800' textColorClass='text-gray-800' />
			</div>
		);
	}
	
	const handleUpdateSetting = (setting, value) => {
		updateSetting(companyId, setting, value);
	}
	
	
	
	return(
		<>
			<CompanySettingsHeader companyId={companyId} />
			
			<div className="divide-7 divide-gray-200 pt-6">
				<div className="px-4 sm:px-6">
					<div>
						<h2 className="text-lg font-medium leading-6 text-gray-900">Pull Tracker</h2>
						<p className="mt-1 text-sm text-gray-500">Settings specific to the Pull Tracker module</p>
					</div>
					
					<ul role="list" className="mt-2 divide-y divide-gray-200">
						<li className="flex items-center justify-between py-4">
							<div className="flex flex-col basis-1/2">
								<p className="text-sm font-medium text-gray-900">Job Incompletely Pulled email recipients</p>
								<p className="text-sm text-gray-500">This email is sent when a job has some items pulled, but not all items are completely pulled. Comma-delimited list.</p>
							</div>
							
							<div className="flex flex-grow-1 basis-1/2">
								<TextInput 
									type="text" 
									value={settings.job_incompletely_pulled_email_recipients} 
									changeHandler={(value) => handleUpdateSetting("job_incompletely_pulled_email_recipients", value)} 
									className={`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 
												focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
									onFocus={(e) => e.target.select()}
								/>
							</div>							
						</li>
						
						<li className="flex items-center justify-between py-4">
							<div className="flex flex-col basis-1/2">
								<p className="text-sm font-medium text-gray-900">PO Incompletely Updated email recipients</p>
								<p className="text-sm text-gray-500">This email is sent when a PO has some items received, but not all items are completely received. Comma-delimited list.</p>
							</div>
							
							<div className="flex flex-grow-1 basis-1/2">
								<TextInput 
									type="text" 
									value={settings.po_incompletely_received_email_recipients} 
									changeHandler={(value) => handleUpdateSetting("po_incompletely_received_email_recipients", value)} 
									className={`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 
												focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
									onFocus={(e) => e.target.select()}
								/>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</>
	);
}