import _ from 'lodash';
import { immerable } from 'immer';
import { JobEquipment } from 'models/JobEquipment';

export class HvacJobStage
{
	[immerable] = true;
	
	_original;
	
	stage;
	stage_name;
	job_number;
	status;
	bin_number;
	crew_number;
	material_pulled;
	equipment_pulled;
	number_of_systems;
	date_modified;
	
	equipments = [];
	
	constructor(data)
	{
		this._original = data;
		
		_.assign(this, _.omit(data, ['equipments']));
		
		if (Array.isArray(data.equipments)) {
			this.equipments = data.equipments.map(equipment => {
				return new JobEquipment(equipment);
			});
		}
	}
	
	isUnprocessed() {
		let binNumberComplete = !_.isEmpty(this.bin_number);
		
		let allEquipmentUnprocessed = _.every(this.equipments, equipment => {
			return equipment.isUnprocessed();
		});
		
		return !binNumberComplete && allEquipmentUnprocessed;
	}
	
	isInProcess() {
		let binNumberComplete = !_.isEmpty(this.bin_number);
		
		let someEquipmentInProcessOrComplete = _.some(this.equipments, equipment => {
			return equipment.isInProcess || equipment.isComplete();
		});
		
		return binNumberComplete || someEquipmentInProcessOrComplete;
	}
	
	isComplete() {
		if (_.isEmpty(this.bin_number)) {
			return false;
		}
		
		_.forEach(this.equipments, equipment => {
			if (!equipment.isComplete()) {
				return false;
			}
		});
		
		return true;
	}
	
	isEquipmentPulled() {
		let equipmentPulled = true;
		this.equipments.forEach(equipment => {
			if (!equipment.serial_number | !equipment.manufacturer_part_number) {
				equipmentPulled = false;
			}
		});
		
		return equipmentPulled;
	}
	
	binNumberIsDirty() {
		return this._original.bin_number != this.bin_number;
	}
	
	getDirtyEquipments() {
		return this.equipments.filter(equipment => {
			return equipment.isDirty();
		});
	}
}