import _ from 'lodash';
import { immerable } from 'immer';

export class JobEquipment
{
	[immerable] = true;
	
	_original;
	
	job_number;
	item;
	stage;
	stage_name;
	system_number;
	manufacturer_part_number;
	serial_number;
	equipment_type;
	item_mismatch;
	date_modified;
	
	get key() {
		return `key_${this.job_number}_${this.item}_${this.system_number}`;
	}
	
	constructor(data) {
		this._original = data;
		
		this.job_number = data.job_number;
		this.item = data.item;
		this.stage = data.stage;
		this.stage_name = data.stage_name;
		this.system_number = data.system_number;
		this.manufacturer_part_number = data.manufacturer_part_number;
		this.serial_number = data.serial_number;
		this.equipment_type = data.equipment_type;
		this.item_mismatch = data.item_mismatch;
		this.date_modified = data.date_modified;
	}
	
	isUnprocessed() {
		return _.isEmpty(this.bin_number) && _.isEmpty(this.manufacturer_part_number) && _.isEmpty(this.serial_number);
	}
	
	isInProcess() {
		return !_.isEmpty(this.bin_number) || !_.isEmpty(this.manufacturer_part_number) || !_.isEmpty(this.serial_number);
	}
	
	isComplete() {
		return !_.isEmpty(this.manufacturer_part_number) && !_.isEmpty(this.serial_number);
	}

	isDirty() {
		let serialNumberChanged = this.serial_number != this._original.serial_number;
		let manufacturerPartNumberChanged = this._original.manufacturer_part_number != this.manufacturer_part_number;
		
		return (serialNumberChanged || manufacturerPartNumberChanged);
	}

	toPlain() {
		let output = {
			job_number: this.job_number,
			item: this.item,
			stage: this.stage,
			stage_name: this.stage_name,
			system_number: this.system_number,
			manufacturer_part_number: this.manufacturer_part_number,
			serial_number: this.serial_number,
			equipment_type: this.equipment_type,
			item_mismatch: this.item_mismatch,
			date_modified: this.date_modified			
		}

		return output;
	}
}