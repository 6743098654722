import _ from 'lodash';
import { immerable } from 'immer';
import { DateTime } from 'luxon';

import { JobItem } from 'models/JobItem';
import { HvacJobStage } from 'models/HvacJobStage';

export class Job
{
	[immerable] = true;
	
	_original;
	_date;

	job_number;
	address;
	items = [];
	stages = [];

	get date() {
		return this._date;
	}
	set date(value) {
		if (value instanceof Date) {
			this._date = DateTime.fromJSDate(value);
		}

		if (value instanceof DateTime) {
			this._date = value;
		}

		// Luxon can't format arbitrarily formatted strings, so this will produce
		// 'Invalid DateTime' as the string output if the input is invalid
		// We'll just assume it's an ISO date string, so that we at least always
		// have a Luxon DateTime here
		this._date = DateTime.fromISO(value);
	}
	
	constructor(data)
	{
		this._original = data;
		
		_.assign(this, _.omit(data, ['items', 'stages']));
		
		if (Array.isArray(data.items)) {
			this.items = data.items.map(item => {
				return new JobItem(item);
			});
		}
		
		if (Array.isArray(data.stages)) {
			this.stages = data.stages.map(stage => {
				return new HvacJobStage(stage);
			});
		}
	}
	
	activeStage() {
		return _.first(_.orderBy(this.stages, ['stage', 'asc']));
	}
	
	incompleteItemsCount() {
		let count = 0;
		
		_.forEach(this.items, item => {
			if (!item.isComplete()) {
				count++;
			}
		});
		
		_.forEach(this.stages, stage => {
			_.forEach(stage.equipments, equipment => {
				if (!equipment.isComplete()) {
					count++;
				}
			})
		});
		
		return count;
	}
	
	itemsCount() {
		let count = 0;
		
		_.forEach(this.items, item => {
			count++;
		});
		
		_.forEach(this.stages, stage => {
			_.forEach(stage.equipments, equipment => {
				count++;
			});
		});
		
		return count;
	}
	
	hasStages() {
		return this.stages.length > 0;
	}
	
	hasItems() {
		return this.itemsCount() > 0;
	}
	
	isUnprocessed() {
		return _.every(this.items, item => {
			return item.isUnprocessed();
		}) && _.every(this.stages, stage => {
			return stage.isUnprocessed();
		});
	}
	
	isInProcess() {
		return !this.isUnprocessed() && !this.isComplete();
	}
	
	isComplete() {
		return _.every(this.items, item => {
			return item.isComplete();
		}) && _.every(this.stages, stage => {
			return stage.isComplete();
		});
	}

	getDirtyItems() {
		return this.items.filter(item => {
			return item.isDirty();
		});
	}
}