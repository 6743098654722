import { CheckIcon } from '@heroicons/react/solid';

import { ButtonLoadingSpinner } from 'components/common/ButtonLoadingSpinner';
import { Button } from 'components/common/buttons/Button';
import { TextInput } from 'components/common/forms/TextInput';
import { usePoStore } from 'stores/po-store';

export function PurchaseOrderLineItemCard({ lineItem }) {
	const hideCompletedLineItems = usePoStore((state) => state.hideCompletedLineItems);
	const isUpdatingAllLineItems = usePoStore((state) => state.isUpdatingAllLineItems);
	const lineItemBeingUpdated = usePoStore((state) => state.lineItemBeingUpdated);
	const updateQuantityToAdd = usePoStore((state) => state.updateQuantityToAdd);
	const receivePoLineItem = usePoStore((state) => state.receivePoLineItem);

	const updateQuantity = (value) => {
		updateQuantityToAdd(lineItem.po_number, lineItem.item, value);
	}
	
	const receive = () => {
		if (isNaN(parseInt(lineItem.quantity_to_add))) {
			return;
		} else {
			receivePoLineItem(lineItem.po_number, lineItem.item, lineItem.toPlain());
		}
	}

	let isHidden = false;
	if (hideCompletedLineItems) {
		isHidden = (lineItem.quantity_received == lineItem.quantity_ordered);
	}
	let isUpdating = (isUpdatingAllLineItems || (lineItemBeingUpdated !== null));
	let buttonIcon = (lineItemBeingUpdated == lineItem.item || isUpdatingAllLineItems) ? 
		<ButtonLoadingSpinner />
		: <CheckIcon className="-ml-1 mr-2 h-5 w-5 text-white" />
	

	
	return(
		<div className={`relative rounded-lg border border-gray-300 bg-white py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 ${isHidden ? 'hidden' : 'visible'}`}>
			<div className="flex flex-grow divide-y sm:divide-y-0 sm:divide-x flex-col sm:flex-row">
				<div className="flex flex-col flex-shrink-0 basis-1/2 pl-6 pb-4 sm:pb-0">
					<div className="font-semibold text-gray-900 mb-3">Part #: {lineItem.item}</div>
					<div className="mb-3">
						<div className="text-sm font-medium text-gray-900">Manufacturer</div>
						<div className="text-xs text-gray-500">{lineItem.manufacturer}</div>
					</div>
					<div className="mb-3">
						<div className="text-sm font-medium text-gray-900">Manufacturer Part #</div>
						<div className="text-xs text-gray-500">{lineItem.manufacturer_part_number}</div>
					</div>
					<div>
						<div className="text-sm font-medium text-gray-900">Description</div>
						<div className="text-xs text-gray-500">{lineItem.description}</div>
					</div>
				</div>
				
				<div className="flex flex-col flex-shrink-1 basis-1/2 pt-4 sm:pt-0">
					<div className="flex items-center justify-center">
						<div className="flex-shrink w-20 text-center mr-4 xl:mr-2 2xl:mr-4">
							<div className="text-sm font-medium text-gray-900">TOTAL<br/>ORDERED</div>
							<div className="flex w-16 h-10 mx-auto items-center justify-center font-bold text-green-600">{lineItem.quantity_ordered}</div>
							<div className="w-16 h-10 mx-auto">
								<TextInput 
									id={lineItem.key} 
									type="text" 
									value={lineItem.quantity_to_add} 
									changeHandler={updateQuantity} 
									className={`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 
												focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-center font-bold`} 
									onFocus={(e) => e.target.select()} 
									disabled={isUpdating} 
									/>
							</div>
						</div>
						
						<div className="flex-shrink text-center ml-4 xl:ml-2 2xl:mr-4">
							<div className="text-sm font-medium text-gray-900">TOTAL<br/>RECEIVED</div>
							<div className={`flex w-16 h-10 mx-auto items-center justify-center font-bold ${lineItem.quantity_received === lineItem.quantity_ordered ? 'text-green-600' : 'text-red-600'}`}>{lineItem.quantity_received}</div>
							<Button variant="solid" color="primary" leftIcon={buttonIcon} onClick={() => { receive() }}>RECEIVE</Button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}