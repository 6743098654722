import { useEffect, useState } from 'react';
import { Button } from 'components/common/buttons/Button';
import { TextInput } from 'components/common/forms/TextInput';
import { SearchInput } from 'components/common/forms/SearchInput';
import { WarehouseLocationSelector } from 'components/inventory/WarehouseLocationSelector';
import { useInventoryItemStore, useWarehouseBinStore } from 'stores';
import { AuthService } from 'services/AuthService';
import { ButtonLoadingSpinner } from 'components/common/ButtonLoadingSpinner';
import _ from 'lodash';


export function BinItemsTable({ bin, items=[] }) {
	const selectedWarehouseLocation = useWarehouseBinStore((state) => state.selectedWarehouseLocation);
	
	const [filter, setFilter] = useState("");
	
	let filteredItems = _.filter(items, item => {
		let filterText = filter.toLowerCase();
		
		if (filterText) {
			if (!item.part_number.toLowerCase().includes(filterText) &&
				!item.description.toLowerCase().includes(filterText) &&
				!item.manufacturer.toLowerCase().includes(filterText) &&
				!item.manufacturer_part_number.toLowerCase().includes(filterText)
			) {
				return false;
			}
			
		}
		
		return true;
	});
	
	return(
		<>
		<div className="flex flex-col sm:flex-row flex-grow border-b border-gray-300 pb-4 pt-2 justify-end items-center sticky top-44 sm:top-20 lg:top-24 bg-gray-100 lg:pb-4 lg:pt-8 lg:border-transparent bg-clip-padding z-10">
		
			<div className="flex items-center sm:mr-10">
				<SearchInput placeholder="Filter" value={filter} setValue={setFilter} onFocus={() => setFilter('')} />
			</div>
		
			<div className="flex flex-col sm:flex-row items-center mt-4 sm:mt-0">
				<div className="mr-2 text-xs lg:mr-4">Counting Location:</div>
				
				<WarehouseLocationSelector />
			</div>
		</div>
		<div className="flex lg:table min-w-full lg:divide-y lg:divide-gray-300 border-collapse">
			<BinItemsTableHead />
			
			<div className="grid grid-cols-1 gap-4 sm:grid-cols-2 bg-transparent lg:table-row-group lg:bg-white lg:divide-y lg:divide-gray-200">
				{filteredItems.map((item, index) => {
					return <BinItemsTableRow key={index} bin={bin} item={item} warehouseLocation={selectedWarehouseLocation} />
				})}
			</div>
		</div>
		</>
	);
}



function BinItemsTableHead() {
	return(
		<div className="hidden lg:table-header-group bg-gray-50 sticky top-44 border-b border-gray-500">
			<div className="table-row divide-x divide-gray-200">
				<div scope="col" className="table-cell py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pl-6">
					Part # / Description
				</div>
				
				<div scope="col" className="table-cell px-4 py-3.5 text-left text-sm font-semibold text-gray-900">
					Manufacturer Info
				</div>
				
				<div scope="col" className="table-cell px-4 py-3.5 text-left text-sm font-semibold text-gray-900">
					Add Items
				</div>
				
				<div scope="col" className="table-cell px-4 py-3.5 text-left text-sm font-semibold text-gray-900">
					Quantity
				</div>
				
				<div scope="col" className="table-cell px-4 py-3.5 text-left text-sm font-semibold text-gray-900">
					Counted On
				</div>
				
				<div scope="col" className="table-cell py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pr-6">
					
				</div>
			</div>
		</div>
	);
}



function BinItemsTableRow({ bin, item, warehouseLocation }) {
	const updateCount = useInventoryItemStore((state) => state.updateCount);
	const updateStatus = useInventoryItemStore((state) => state.updateStatus);
	const itemBeingUpdated = useInventoryItemStore((state) => state.itemBeingUpdated);
	
	const [amountToAdd, setAmountToAdd] = useState(0);
	
	const [complete, setComplete] = useState(false);
	const [showComplete, setShowComplete] = useState(false);
		
	const addToCurrentCountingArea = async () => {
		let quantity = parseInt(amountToAdd);
		
		if (isNaN(quantity)) {
			return;
		}
		
		let warehouseCount = item.counts[warehouseLocation.code];
		
		let payload = {
			part_number: warehouseCount.part_number,
			location_code: warehouseCount.location_code,
			location_description: warehouseCount.location_description,
			type_code: warehouseCount.type_code,
			quantity_counted: parseInt(warehouseCount.quantity_counted) + quantity
		};
		
		if (item.status == "uncounted") {
			await handleStatusUpdate("started");
		}
		
		await updateCount(bin.bin_id, item.part_number, payload);
		
		setAmountToAdd(0);
	}
	
	const handleStatusUpdate = async (newStatus) => {
		let payload = {
			status: newStatus,
			location_code: warehouseLocation.code
		};
		
		await updateStatus(item.bin_id, item.part_number, payload);
	}
	
	
	
	let countDom = () => {
		let id = AuthService.user.activeCompany.id;
		
		if (id == 1) {
			let rr = item.counts["1"];
			let temple = item.counts["2"];
			
			return(
				<div className="flex">
					<div>
						<div className="font-bold">Round Rock</div>
						<div className="font-bold border-b border-gray-300">Temple</div>
						<div className="font-bold">Total</div>
					</div>
					
					<div className="text-right w-8">
						<div className="pl-1.5">{rr.quantity_counted}</div>
						<div className="pl-1.5 border-b border-gray-300">{temple.quantity_counted}</div>
						<div className="pl-1.5">{item.getTotalCount()}</div>
					</div>
				</div>
			);
		}
		
		let warehouseCount = item.counts["1"];
		let label = (id == 2) ? "Round Rock" : "San Antonio";
		
		return(
			<div className="flex">
				<div>
					<div className="font-bold">{label}</div>
					<div className="font-bold">Total</div>
				</div>
				
				<div className="text-right w-8">
					<div className="pl-1.5">{warehouseCount.quantity_counted}</div>
					<div className="pl-1.5">{item.getTotalCount()}</div>
				</div>
			</div>
		);
	}
	
	let countedDateDom = () => {
		let id = AuthService.user.activeCompany.id;
		
		if (id == 1) {
			let rr = item.counts["1"];
			let temple = item.counts["2"];
			
			return(
				<>
				<div>{rr?.counted_date?.toISODate() ?? ""}&nbsp;</div>
				<div>{temple?.counted_date?.toISODate() ?? ""}&nbsp;</div>
				<div>&nbsp;</div>
				</>
			);
		}
		
		let warehouseCount = item.counts["1"];
		
		return(
			<>
			<div>{warehouseCount?.counted_date?.toISODate() ?? ""}&nbsp;</div>
			<div>&nbsp;</div>
			</>
		);
	}
	
	let addButtonIcon = (itemBeingUpdated == item.part_number) ?
		<ButtonLoadingSpinner />
		: null
		
	let statusButtonIcon = (itemBeingUpdated == item.part_number) ?
		<ButtonLoadingSpinner />
		: null;
	
	return(
		<div className="flex flex-col flex-grow lg:table-row mb-8 lg:mb-0 rounded-lg lg:rounded-none shadow-lg lg:shadow-none lg:divide-x lg:divide-gray-200 bg-white">
			<div className="flex flex-col lg:table-cell whitespace-nowrap py-4 pl-4 pr-4 text-sm sm:pl-6">
				<div className="lg:hidden">Part #/ Description</div>
				<div className="font-bold">{item.part_number}</div>
				<div className="text-gray-500">{item.description}</div>
			</div>
			
			<div className="flex flex-col lg:table-cell whitespace-nowrap p-4 text-sm">
				<div className="lg:hidden">Manufacturer Info</div>
				<div className="font-bold">{item.manufacturer}</div>
				<div className="text-gray-500">{item.manufacturer_part_number}</div>
			</div>
			
			<div className="flex lg:table-cell whitespace-nowrap p-4 text-sm">
				<div className="flex space-x-3">
					<div className="w-16">
						<TextInput id={item.id} type="text" value={amountToAdd} changeHandler={setAmountToAdd}  onFocus={(e) => e.target.select()} />
					</div>
					<div className="w-24">
						<Button 
							variant="solid" 
							color="primary" 
							onClick={addToCurrentCountingArea} 
							disabled={itemBeingUpdated == item.part_number}
							leftIcon={addButtonIcon}
						>
							ADD
						</Button>
					</div>
				</div>
			</div>
			
			<div className="flex whitespace-nowrap p-4 text-sm lg:hidden">
				<div>
					{countDom()}
				</div>
				
				<div className="ml-2">
					{countedDateDom()}
				</div>
			</div>
			
			<div className="hidden lg:flex lg:table-cell whitespace-nowrap p-4 text-sm">
				{countDom()}
			</div>
			
			<div className="hidden lg:flex lg:table-cell whitespace-nowrap p-4 text-sm">
				{countedDateDom()}
			</div>
			
			<div className="flex lg:table-cell whitespace-nowrap py-4 pl-4 pr-4 text-sm sm:pr-6">
				{item.status === 'uncounted' &&
					<Button 
						variant="solid" 
						color="primary" 
						onClick={() => handleStatusUpdate('started')}
						disabled={itemBeingUpdated == item.part_number}
						leftIcon={statusButtonIcon}
					>Mark Started</Button>
				}
				
				{item.status === 'started' &&
					<Button 
						variant="solid" 
						color="success" 
						onClick={() => handleStatusUpdate('completed')}
						disabled={itemBeingUpdated == item.part_number}
						leftIcon={statusButtonIcon}
					>Mark Completed</Button>
				}
				
				{item.status === 'completed' &&
					<Button 
						variant="solid" 
						color="white" 
						onClick={() => handleStatusUpdate('uncounted')}
						disabled={itemBeingUpdated == item.part_number}
						leftIcon={statusButtonIcon}
					>Mark Uncounted</Button>
				}
			</div>
		</div>
	);
}