import { Fragment, useContext, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import voca from 'voca';
import { SidebarSearch } from 'components/common/SidebarSearch';
import { LoadingSpinner } from 'components/common/LoadingSpinner';
import { inventory } from 'services/api';
import { useInventoryItemStore, useWarehouseBinStore } from 'stores';
import { AppContext } from 'contexts/App';

export function InventorySidebarContents() {
	const nav = useNavigate();
	const app = useContext(AppContext);
	const warehouseBins = useWarehouseBinStore((state) => state.warehouseBins);
	const isInitialized = useWarehouseBinStore((state) => state.isInitialized);
	const isLoading = useWarehouseBinStore((state) => state.isLoading);
	const itemStore = useInventoryItemStore((state) => {
		return {
			inventoryItems: state.inventoryItems,
			isLoading: state.isLoading
		}
	});
			
	const hasError = useWarehouseBinStore((state) => state.hasError);
	const errorMessage = useWarehouseBinStore((state) => state.errorMessage);
	
	const [filter, setFilter] = useState("");
	
	const filteredWarehouseBins = useMemo(() => {
			let lowerFilter = filter.toLowerCase();
						
			return _.filter(warehouseBins, bin => {
				let lowerDescription = bin.description.toLowerCase() ?? "";
				let binItemPartNumbers = _.map(_.filter(itemStore.inventoryItems, x => x.bin_id == bin.bin_id), x => {
					return {
						part_number: x.part_number.toLowerCase() ?? "",
						manufacturer_part_number: x.manufacturer_part_number.toLowerCase() ?? ""
					};
				});
				
				return (lowerDescription.includes(lowerFilter) || _.some(binItemPartNumbers, (x) => {
					return x.part_number.includes(lowerFilter) || x.manufacturer_part_number.includes(lowerFilter)
				}));
			});		
	}, [filter, warehouseBins, itemStore.inventoryItems]);
	
	
	if (!isInitialized || isLoading || itemStore.inventoryItems.length === 0 || itemStore.isLoading) {
		return <LoadingSpinner />;
	}
	
	if (hasError) {
		return <span>`Error: ${errorMessage}`</span>;
	}



	return(	
		<SidebarSearch>
			<SidebarSearch.FreeTextFilter setFilter={setFilter} />
			
			<SidebarSearch.List>
				{filteredWarehouseBins.map(bin => {
					return <SidebarSearch.Item
						key={bin.bin_id}
						title={voca.titleCase(bin.description)}
						subtitle={bin.bin_id}
						badge={bin.part_number_count}
						show={true}
						//show={showItem(bin)}
						onClick={() => {
							app.setSidebarOpen(false);
							nav(`/inventory/bins/${bin.bin_id}`)
						}}
					/>
				})}
			</SidebarSearch.List>
		</SidebarSearch>
	);
}