import { DateTime } from 'luxon';
import { immerable } from 'immer';
import { PurchaseOrderLineItem } from 'models/PurchaseOrderLineItem';

export class PurchaseOrder
{
	[immerable] = true;
	
	_original;
	_date;
	
	po_number;
	date;
	status;
	vendor_number;
	vendor_name;
	comment;
	line_items = [];

	get date() {
		return this._date;
	}
	set date(value) {
		if (value instanceof Date) {
			this._date = DateTime.fromJSDate(value);
		}

		if (value instanceof DateTime) {
			this._date = value;
		}

		// Luxon can't format arbitrarily formatted strings, so this will produce
		// 'Invalid DateTime' as the string output if the input is invalid
		// We'll just assume it's an ISO date string, so that we at least always
		// have a Luxon DateTime here
		this._date = DateTime.fromISO(value);
	}

	get incomplete_line_items() {
		return this.line_items.reduce((carry, x) => {
			if (x.quantity_ordered != x.quantity_received) carry++;
			return carry;
		}, 0);
	}
	
	constructor(data) {
		this._original = data;
		
		this.po_number = data.po_number;
		this.date = data.date;
		this.status = data.status;
		this.vendor_number = data.vendor_number;
		this.vendor_name = data.vendor_name;
		this.comment = data?.comment;
		
		if (Array.isArray(data.line_items)) {
			this.line_items = data.line_items.map(data => {
				return new PurchaseOrderLineItem(data);
			});
		}
	}

	getDirtyLineItems() {
		return this.line_items.filter(lineItem => {
			return lineItem.isDirty();
		});
	}
}
