import { immerable } from 'immer'
import _ from 'lodash';
import { DateTime } from 'luxon';

export class WarehouseCount
{
	[immerable] = true;
	
	_original;
	_counted_date;
	
	part_number;
	location_code;
	location_description;
	type_code;
	quantity_counted;
	
	get counted_date() {
		return this._counted_date;
	}
	set counted_date(value) {
		if (!value) {
			this._counted_date = null;
			return;
		}
		
		if (value instanceof Date) {
			this._counted_date = DateTime.fromJSDate(value);
		}
		
		if (value instanceof DateTime) {
			this._counted_date = value;
		}
		
		// Luxon can't format arbitrarily formatted strings, so this will produce
		// 'Invalid DateTime' as the string output if the input is invalid
		// We'll just assume it's an ISO date string, so that we at least always
		// have a Luxon DateTime here
		this._counted_date = DateTime.fromISO(value.substring(0,10));
	}
	
	constructor(data) {
		this._original = data;
		
		_.assign(this, _.omit(data));
	}
	
	isDirty() {
		let isDirty = false;
		
		_.each(this.counts, (count) => {
			if (count.isDirty()) {
				isDirty = true;
			}
		});
		
		return isDirty;
	}
}