import { Fragment, useContext } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronRightIcon, SearchIcon, SelectorIcon } from '@heroicons/react/outline';
import { classNames } from 'helpers';

function SidebarSearch({ children, ...rest }) {
	return(
		<div {...rest}>
			{children}
		</div>
	);
}

function SidebarSearchDropdownFilter({ options, selected, setSelected }) {	
	return(
		<Listbox value={selected} onChange={setSelected}>
			{({ open }) => (
				<div className="mt-1 relative">
					<Listbox.Button className="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
						<span className="block truncate">{selected.label}</span>
						<span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
							<SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
						</span>
					</Listbox.Button>
					
					<Transition
						show={open}
						as={Fragment}
						leave="transition ease-in duration-100"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
							{options.map(option => (
								<Listbox.Option className={({ active }) => classNames(active ? 'text-white bg-indigo-600' : 'text-gray-900','cursor-default select-none relative py-2 pl-3 pr-9')}
									key={option.id} 
									value={option}
								>
									{({ selected, active }) => {
										
										return(
										<>
											<span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>{option.label}</span>
											
											{selected ? (
												<span className={classNames(active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4')}>
													<CheckIcon className="h-5 w-5" aria-hidden="true" />
												</span>
											) : null}
										</>
									
									)}}
								</Listbox.Option>
							))}
						</Listbox.Options>
					</Transition>
				</div>
			)}
		</Listbox>
	);
}

function SidebarSearchFreeTextFilter({ setFilter, ...rest }) {
	return(
		<div className="search-input relative text-gray-400 focus-within:text-gray-600 mb-8" {...rest}>
			<div className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
				<SearchIcon className="h-5 w-5" aria-hidden="true" />
			</div>
			<input id="search" className="block w-full bg-white py-2 pl-10 pr-3 border border-transparent rounded-md leading-5 text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-600 focus:ring-white focus:border-white sm:text-sm" placeholder="Search" type="search" name="search" onChange={(e) => setFilter(e.target.value)} />
		</div>
	);
}

function SidebarSearchList({ children, ...rest }) {
	return(
		<div className="bg-white shadow overflow-hidden sm:rounded-md" {...rest}>
			<ul className="search-items-list divide-y divide-gray-200">
				{children}
			</ul>
		</div>
	);
}

function SidebarSearchItem({ title, subtitle=null, badge=null, show=true, ...rest }) {
	return(
		<li className={`w-full cursor-pointer hover:bg-gray-100 ${show ? 'visible' : 'hidden'}`} {...rest}>
			<div className="flex items-center px-2 py-4 sm:px-2">
				<div className="min-w-0 flex flex-1 items-center">
					<div className="flex flex-1 flex-col">
						{subtitle === null &&
							<div className="sm:text-sm">{title}</div>
						}
						{subtitle !== null &&
							<>
							<div className="sm:text-sm">{title}</div>
							<div className="sm:text-sm text-gray-400">{subtitle}</div>
							</>
						}
					</div>
					{badge !== null &&
						<span className="bg-gray-900 ml-3 inline-block py-0.5 px-3 text-xs font-medium rounded-full text-white">
							{badge}
						</span>
					}
				</div>
				<div className="">
					<ChevronRightIcon className="h-5 w-5 text-gray-400" />
				</div>					
			</div>
		</li>
	);
}

SidebarSearch.DropdownFilter = SidebarSearchDropdownFilter;
SidebarSearch.FreeTextFilter = SidebarSearchFreeTextFilter;
SidebarSearch.List = SidebarSearchList;
SidebarSearch.Item = SidebarSearchItem;

export {
	SidebarSearch
}