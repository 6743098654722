import { Fragment, useContext, useEffect, useState } from 'react';
import { AppContext } from 'contexts/App';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';
import { classNames } from 'helpers';
import { AuthService } from 'services/AuthService';
import { auth as authApi } from 'services/api';
import { companies } from 'companies';


/** TODO: The dropdown checkbox seems to lose state - looks like maybe the objects aren't equal to one another, but just look the same **/
export function CompanySelector() {
	const changeCompany = async (selectedCompany) => {
		await AuthService.updateActiveCompany(selectedCompany.id);
		
		setTimeout(() => {
			window.location = '/';
		}, 10);
	}
	
	const canChangeCompanies = (AuthService.user && AuthService.user.canChangeCompanies()) || false;
	
	return(
		<Listbox value={AuthService.user.activeCompany} onChange={changeCompany} disabled={!canChangeCompanies}>
			{({ open }) => (
				<div className="mt-1 relative md:w-52">
					<Listbox.Button className="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
						<span className="block truncate">{AuthService.user.activeCompany.name}</span>
						<span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
							<SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
						</span>
					</Listbox.Button>
					
					<Transition
						show={open}
						as={Fragment}
						leave="transition ease-in duration-100"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
							{companies.map(company => (
								<Listbox.Option className={({ active }) => classNames(active ? 'text-white bg-indigo-600' : 'text-gray-900','cursor-default select-none relative py-2 pl-3 pr-9')}
									key={company.id} 
									value={company}
								>
									{({ selected, active }) => {
										
										return(
										<>
											<span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>{company.name}</span>
											
											{selected ? (
												<span className={classNames(active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4')}>
													<CheckIcon className="h-5 w-5" aria-hidden="true" />
												</span>
											) : null}
										</>
									
									)}}
								</Listbox.Option>
							))}
						</Listbox.Options>
					</Transition>
				</div>
			)}
		</Listbox>
	);
}