import { CalendarIcon, CheckIcon, MailIcon } from '@heroicons/react/solid';
import voca from 'voca';

import { ButtonLoadingSpinner } from 'components/common/ButtonLoadingSpinner';
import { Button } from 'components/common/buttons/Button';
import { HideToggle } from 'components/common/buttons/HideToggle';
import { usePoStore } from 'stores/po-store';

export function PurchaseOrderDetailsHeader({ po }) {
	const hideCompletedLineItems = usePoStore((state) => state.hideCompletedLineItems);
	const toggleHideCompletedLineItems = usePoStore((state) => state.toggleHideCompletedLineItems);
	const isUpdatingAllLineItems = usePoStore((state) => state.isUpdatingAllLineItems);
	const lineItemBeingUpdated = usePoStore((state) => state.lineItemBeingUpdated);
	const isNotifying = usePoStore((state) => state.isNotifying);
	const receivePoLineItems = usePoStore((state) => state.receivePoLineItems);
	const notifyIfIncomplete = usePoStore((state) => state.notifyIfIncomplete);

	const receiveAll = () => {
		receivePoLineItems(po.po_number, po.line_items.map(item => {
			return item.toPlain();
		}));
	}
	
	const notify = () => {
		notifyIfIncomplete(po.po_number);
	}

	let isUpdating = (isUpdatingAllLineItems || (lineItemBeingUpdated !== null));
	let checkIcon = (isUpdatingAllLineItems) ? 
		<ButtonLoadingSpinner />
		: <CheckIcon className="-ml-1 mr-2 h-5 w-5 text-white" />;
	let mailIcon = (isNotifying) ?
		<ButtonLoadingSpinner />
		: <MailIcon className="-ml-1 mr-2 h-5 w-5 text-white" />;
		

	return(
		<>
		<div className="lg:flex lg:items-center lg:justify-between mb-4">
			<div className="flex-1 min-w-0">
				<h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl">PO# {po?.po_number}<span className="text-xl font-semibold"> | {voca.titleCase(po?.vendor_name)}</span></h2>
				
				<div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
					<div className="mt-2 flex items-center text-sm text-gray-500">
						<CalendarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
						{po.date?.toLocaleString()}
					</div>
				</div>
			</div>
				
			<div className="mt-5 flex lg:mt-0 lg:ml-4">
				<span>
					<HideToggle label="Hide Completed" hide={hideCompletedLineItems} setter={toggleHideCompletedLineItems} />
				</span>
				
				<span className="ml-3">
					<Button variant="solid" color="success" leftIcon={checkIcon} onClick={receiveAll} disabled={isUpdating}>Receive All and Notify</Button>
				</span>
				
				<span className="ml-3">
					<Button variant="solid" color="primary" leftIcon={mailIcon} onClick={notify} disabled={isNotifying || isUpdating}>Notify</Button>
				</span>
			</div>
		</div>
		
		{po?.comment !== null && po?.comment !== "" && po?.comment !== undefined &&
			<div className="px-4 py-4 mt-3 mb-8 text-xs font-semibold bg-gray-200 border border-orange-500 text-orange-700">
				{po?.comment}
			</div>
		}
		</>
	);	
}