import { Transition } from '@headlessui/react';

const TextInputLabel = ({ className='block text-sm font-medium text-gray-700', htmlFor, children }) => {
	return(
		<label htmlFor={htmlFor} className={className}>
			{children}
		</label>
	);
}

const TextInput = ({ 
	value,
	changeHandler,
	className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm',
	required=false,
	centered=false,
	errorMessage='',
	...rest
}) => {

	return(
		<>
			<input
				value={value}
				onChange={e => changeHandler(e.target.value)}
				className={className} 
				{...rest}
			/>
			
			<Transition show={errorMessage !== ''}
				enter="transform origin-top duration-400"
				enterFrom="scale-y-0"
				enterTo="scale-y-100"
				leave="transition-all transform origin-top duration-400"
				leaveFrom="scale-y-100"
				leaveTo="scale-y-0"
			>
				<p className="absolute font-medium text-xs text-red-700 px-3 mt-1">{errorMessage}</p>
			</Transition>
		</>
	);
}

export {
	TextInputLabel,
	TextInput
}