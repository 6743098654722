import create from 'zustand';
import { immer } from 'zustand/middleware/immer';
import produce from 'immer';
import pipe from 'ramda/es/pipe';

/*
|-----------------------------------------------------------------------------
| Application State
|-----------------------------------------------------------------------------
| 
| The application uses a single Zustand store to manage stage. Zustand allows
| the use of slices, to allow you to mutate only on the bits of state that your
| component cares about, and also has a special syntax for fetching from the 
| store while inside an action. This is easiest to manage 
*/

// Log every time state is changed
const log = (config) => (set, get, api) =>
  config(
    (args) => {
      //console.log('  applying', args)
      set(args)
      //console.log('  new state', get())
    },
    get,
    api
  )

// Turn the set method into an immer proxy
/**
const immer = (config) => (set, get, api) => {
	api.setState = (fn) => set(produce(fn));
	return config((fn) => set(produce(fn)), get, api);
}
**/

const createStore = pipe(log, immer, create);

export {
	createStore
}