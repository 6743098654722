export function Button({ 
	onClick, 
	children, 
	variant='solid', 
	color='primary',
	fullWidth=true,
	leftIcon=null,
	rightIcon=null,
	...rest
}) {
	let baseClasses = 'items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm focus:outline-none disabled:bg-gray-400';
	let colorClasses = getColorClasses(variant, color);
	let widthClasses = getWidthClasses(fullWidth);
	
	let buttonClasses = `${baseClasses} ${colorClasses} ${widthClasses}`;
	
	return(
		<button
			className={buttonClasses}
			onClick={onClick}
			{...rest}
		>
			{leftIcon !== null &&
				leftIcon
			}
			{children}
			{rightIcon !== null &&
				rightIcon
			}
		</button>
	);
}

function getColorClasses(variant, color) {
	let type = `${variant}-${color}`;
	
	switch(type) {
		case 'solid-secondary':
			return 'bg-indigo-100 hover:bg-indigo-200 focus:ring-indigo-200 text-gray-800';
		case 'solid-success':
			return 'bg-green-600 hover:bg-green-700 focus:ring-green-500 text-white';
		case 'solid-error':
			return 'bg-red-600 hover:bg-red-700 focus:ring-red-500 text-white';
		case 'solid-white':
			return 'bg-white hover:bg-gray-50 focus:ring-gray-500 focus:ring-2 border-gray-300 ';
		case 'solid-primary':
		default:
			return 'bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 text-white';
	}
}

function getWidthClasses(isFullWidth) {
	if (isFullWidth) {
		return 'w-full flex justify-center';
	} else {
		return 'inline-flex';
	}
}