import { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { ReactQueryDevtools } from 'react-query/devtools';
import { AppProvider } from 'contexts/App';
import { AppLayout, BlankLayout } from 'components/common/layouts';
import { RequireAuth } from 'components/common/routing';
import { AuthService } from 'services/AuthService';
import { auth as authApi } from 'services/api';

import './App.css';

/**
 * SCREENS
 *
 * All screens should be private EXCEPT for the ones in the /public directory
 **/

// public
import LoadingScreen from 'screens/public/LoadingScreen';
import LoginScreen from 'screens/public/LoginScreen';
import NotFoundScreen from 'screens/public/NotFoundScreen';

// private
import DashboardScreen from 'screens/private/DashboardScreen';
import InventoryScreen from 'screens/private/InventoryScreen';
import PullTrackerScreen from 'screens/private/PullTrackerScreen';
import ReceivePurchaseOrderScreen from 'screens/private/ReceivePurchaseOrderScreen';

import { InventoryDetailScreen } from 'screens/private/InventoryDetailScreen';
import { PullTrackerDetailsScreen } from 'screens/private/PullTrackerDetailsScreen';
import { PurchaseOrderDetailsScreen } from 'screens/private/PurchaseOrderDetailsScreen';

import { SettingsScreen } from 'screens/private/SettingsScreen';
import { GlobalSettingsScreen } from 'screens/private/GlobalSettingsScreen';
import { CompanySettingsScreen } from 'screens/private/CompanySettingsScreen';

function App() {
	const navigate = useNavigate();
	
	useEffect(() => {
		console.log('nav to home');
		navigate('/')
	}, []);	
	
	return (
		<AppProvider>
			<Routes>
				
				<Route path="/" element={<LoadingScreen />} />
				<Route path='/login' element={<LoginScreen />} />
				
				
				<Route path='dashboard/*' element={<RequireAuth><DashboardScreen /></RequireAuth>}/>
				<Route path='inventory/bins' element={<RequireAuth><InventoryScreen /></RequireAuth>}>
					<Route path=':binId' element={<RequireAuth><InventoryDetailScreen /></RequireAuth>} />
				</Route>
				<Route path='pull-tracker' element={<RequireAuth><PullTrackerScreen /></RequireAuth>}>
					<Route path=':jobNumber' element={<RequireAuth><PullTrackerDetailsScreen /></RequireAuth>} />
				</Route>
				<Route path='receive-po' element={<RequireAuth><ReceivePurchaseOrderScreen /></RequireAuth>}>
					<Route path=':poNumber' element={<RequireAuth><PurchaseOrderDetailsScreen /></RequireAuth>} />
				</Route>
				
				<Route path='settings' element={<RequireAuth><SettingsScreen /></RequireAuth>}>
					<Route path='global' element={<RequireAuth><GlobalSettingsScreen /></RequireAuth>} />
					<Route path='company/:companyId'element={<RequireAuth><CompanySettingsScreen /></RequireAuth>} />
				</Route>
				
				<Route path="*" element={<NotFoundScreen />} />
			</Routes>
		</AppProvider>
	);
}

export default App;
