import { MobileMenu } from 'components/common/menus/MobileMenu';

export default function AppLayout({ children="" }) {
	return(
		<div className="app-layout min-h-full bg-gray-100 pb-20 md:pb-8">
			<MobileMenu />
			
			{children}
		</div>
	);
}