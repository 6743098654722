import { TextInput } from 'components/common/forms/TextInput';
import { useJobStore } from 'stores/job-store';

export function JobEquipmentCard({ jobEquipment }) {
	const hideCompletedItems = useJobStore(state => state.hideCompletedItems);
	const isUpdating = useJobStore(state => state.isUpdating);
	const updateSerialNumber = useJobStore(state => state.updateSerialNumber);
	const updateModelNumber = useJobStore(state => state.updateModelNumber);

	const updateSerialNumberHandler = (value) => {
		updateSerialNumber(jobEquipment, value);
	}
	
	const updateModelNumberHandler = (value) => {
		updateModelNumber(jobEquipment, value);
	}

	let isHidden = false;
	if (hideCompletedItems) {
		isHidden = (typeof jobEquipment.serial_number == 'string' && jobEquipment.serial_number != "");
	}



	return(
		<div className={`relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-start hover:border-gray-400 flex-col flex-grow ${isHidden ? 'hidden' : 'visible'}`}>
			<div className="absolute top-0 right-0 font-bold text-white bg-gray-800 flex h-8 w-8 rounded-bl-full justify-center pl-1">
				{jobEquipment.system_number}
			</div>
			
			<div className="flex flex-col items-start w-full mb-1 text-left ">
				<div className="text-sm font-medium text-gray-900">{jobEquipment.item}</div>
			</div>
			
			<div className="flex flex-col items-start w-full mt-4">
				<div className="text-sm font-medium text-gray-900 whitespace-nowrap">Model #</div>
					<TextInput 
						id={jobEquipment.key} 
						type="text" 
						value={jobEquipment.manufacturer_part_number} 
						changeHandler={updateModelNumberHandler} 
						className={`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 
									focus:outline-none focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm text-center font-bold`} 
						onFocus={(e) => e.target.select()} 
						disabled={isUpdating} 
					/>
			</div>
			
			<div className="flex flex-col items-start w-full mt-4">
				<div className="text-sm font-medium text-gray-900 whitespace-nowrap">Serial #</div>
				<TextInput 
					id={jobEquipment.key} 
					type="text" 
					value={jobEquipment.serial_number} 
					changeHandler={updateSerialNumberHandler} 
					className={`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 
								focus:outline-none focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm text-center font-bold`} 
					onFocus={(e) => e.target.select()} 
					disabled={isUpdating} 
				/>
			</div>
		</div>
	);
}