import { AppLayout, ModuleLayout } from 'components/common/layouts';
import { Sidebar } from 'components/common/Sidebar';
import { MainContent } from 'components/common/MainContent';
import { MainContentTabs } from 'components/common/MainContentTabs';

export default function DashboardScreen() {
	return(
		<AppLayout>
			<Sidebar />
			
			<MainContent title="Dashboard">
				<div className="py-4">
					<div className="border-4 border-dashed border-gray-200 rounded-lg h-96" />
				</div>
			</MainContent>
		</AppLayout>
	);
}