import _ from 'lodash';
import { createStore } from 'state';

import { inventory as api } from 'services/api';
import { WarehouseLocation } from 'models/WarehouseLocation';
import { WarehouseBin } from 'models/WarehouseBin';

let state = (set, get) => ({
	isInitialized: false,
	isLoading: false,
	isUpdating: false,
	hasError: false,
	errorMessage: "",
	
	warehouseBins: [],
	warehouseLocations: [],
	selectedWarehouseLocation: {
		code: "1",
		description: "Select a Location"
	},
	
	initializeStore: async () => {
		set((state) => {
			state.isInitialized = false;
			state.isLoading = true;
			state.itemBeingUpdated = null;
			state.equipmentBeingUpdated = null;
			state.hasError = false;
			state.errorMessage = "";
			state.showCompletedLineItems = true;			
		});

		get().getWarehouseLocations();
		await get().getWarehouseBins();

		set((state) => void (state.isInitialized = true));
	},
	
	setError: async (message) => {
		set((state) => { 
			state.hasError = true;
			state.errorMessage = message;
		});
	},

	clearError: async () => {
		set((state) => {
			state.hasError = false;
			state.errorMessage = "";
		});
	},
	
	setSelectedWarehouseLocation: async (selected) => {
		
		
		set((state) => {
			let matchedLocation = _.find(state.warehouseLocations, x => x.code == selected.code);
			if (matchedLocation) {
				state.selectedWarehouseLocation = matchedLocation;
			}
		});
	},
	
	getWarehouseLocations: async () => {
		set((state) => void (state.isLoading = true));
		
		let result = await api.getWarehouseLocations();
		let locations = result.map(record => new WarehouseLocation(record));
		
		set((state) => {
			state.warehouseLocations = locations;
			
			state.isLoading = false;
			return state;
		});
	},
	
	
	
	getWarehouseBins: async () => {
		set((state) => void(state.isLoading = true));
		
		let result = await api.getWarehouseBins();
		let bins = result.map(record => new WarehouseBin(record));
		
		set((state) => {
			state.warehouseBins = bins;
			
			state.isLoading = false;
			return state;
		});
	},
	
	getWarehouseBin: async (binId) => {
		set((state) => void(state.isLoading = true));
		
		let result = await api.getWarehouseBin(binId);
		let bin = new WarehouseBin(result);
		
		set((state) => {
			// TODO - find the index and set with splice
		
			state.isLoading = false;
			return state;
		});
	},
	
});

const useWarehouseBinStore = createStore(state);

export {
	useWarehouseBinStore
}