import { SearchIcon } from '@heroicons/react/outline';

export function SearchInput({ value, setValue, placeholder="Search", ...rest }) {
	return(
		<div className="filter-input relative text-gray-400 focus-within:text-gray-600">
			<div className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
				<SearchIcon className="h-5 w-5" aria-hidden="true" />
			</div>
			<input id="search" className="block w-full bg-white py-2 pl-10 pr-3 border border-transparent rounded-md leading-5 text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-scred-600 focus:ring-white focus:border-white sm:text-sm" placeholder={placeholder} type="search" name="search" value={value} onChange={e => setValue(e.target.value)} {...rest} />
		</div>		
	);
}