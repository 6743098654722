import { Outlet } from 'react-router-dom';
import { AppLayout } from 'components/common/layouts';
import { Sidebar } from 'components/common/Sidebar';
import { MainContent } from 'components/common/MainContent';
import { ReceivePurchaseOrderSidebarContents } from 'components/receive-po/ReceivePurchaseOrderSidebarContents';
import { usePoStore } from 'stores/po-store';

export default function ReceivePurchaseOrderScreen() {
	const isInitialized = usePoStore((state) => state.isInitialized);
	const initializeStore = usePoStore((state) => state.initializeStore);
	
	if (!isInitialized) {
		initializeStore();
	}

	return(
		<AppLayout>
			<Sidebar title="Receive PO">
				<ReceivePurchaseOrderSidebarContents />
			</Sidebar>
			
			<MainContent>
				<Outlet />
			</MainContent>
		</AppLayout>
	);
}
