import _ from 'lodash';
import { companies } from 'companies';

export class User {
	id;
	firstName = "";
	lastName = "";
	email;
	guid;
	domain;
	
	_companyId;
	_activeCompanyId;
	
	get company() {
		return _.find(companies, x => x.id == this._companyId);
	}
	
	get activeCompany() {
		return _.find(companies, x => x.id == this._activeCompanyId);
	}
	
	constructor(data) {
		if (data.hasOwnProperty('name')) {
			let nameParts = data.name.split(' ');
			
			this.firstName = nameParts[0]; 
			this.lastName = nameParts[1];
		} else {
			this.firstName = data.firstName; 
			this.lastName = data.lastName;
		}
		
		this.id = data.id;
		this.email = data.email;
		this.guid = data.guid;
		this.domain = data.domain;
		this._companyId = data.company_id;
		this._activeCompanyId = data.active_company_id;
	}
	
	canChangeCompanies() {
		return this._companyId == 3;
	}
	
	toPlain() {
		return {
			id: this.id,
			name: `${this.firstName} ${this.lastName}`,
			email: this.email,
			guid: this.guid,
			domain: this.domain,
			company_id: this._companyId,
			active_company_id: this._activeCompanyId
		}
	}
}