import { EyeOffIcon } from '@heroicons/react/solid';
import { Button } from 'components/common/buttons/Button';

export function HideToggle({ label, hide, setter }) {
	let icon = <EyeOffIcon className={`-ml-1 mr-2 h-5 w-5 ${hide ? 'text-green-500' : 'text-gray-300'}`} />;
	
	
	const toggle = () => {
		setter(!hide);
	}
	
	return(
		<Button variant="solid" color="white" leftIcon={icon} onClick={toggle}>{label}</Button>
	);
}