import { CalendarIcon, CheckIcon } from '@heroicons/react/solid';
import voca from 'voca';
import { AuthService } from 'services/AuthService';
import { ButtonLoadingSpinner } from 'components/common/ButtonLoadingSpinner';
import { Button } from 'components/common/buttons/Button';
import { HideToggle } from 'components/common/buttons/HideToggle';
import { useJobStore } from 'stores/job-store';

export function PullTrackerDetailsHeader({ job }) {
	const hideCompletedItems = useJobStore((state) => state.hideCompletedItems);
	const toggleHideCompletedItems = useJobStore((state) => state.toggleHideCompletedItems);
	const isUpdating = useJobStore((state) => state.isUpdating);
	const savePullCounts = useJobStore(state => state.savePullCounts);

	const saveCallback = () => {
		savePullCounts(job);
	}


	let buttonIcon = (isUpdating) ? 
		<ButtonLoadingSpinner />
		: <CheckIcon className="-ml-1 mr-2 h-5 w-5 text-white" />

	return(
		<div className="lg:flex lg:items-center lg:justify-between mb-8">
			<div className="flex-1 min-w-0">
				<h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl">{voca.titleCase(job.address)}</h2>
				
				<div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
					<div className="mt-2 flex items-center text-sm text-gray-500">
						<CalendarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
						{job.date.toLocaleString()}
					</div>
				</div>
			</div>
				
			<div className="mt-5 flex lg:mt-0 lg:ml-4">
				<span>
					<HideToggle label="Hide Completed" hide={hideCompletedItems} setter={toggleHideCompletedItems} />
				</span>
				
				<span className="ml-3">
					<Button variant="solid" color="success" leftIcon={buttonIcon} onClick={saveCallback} disabled={isUpdating}>Save Parts</Button>
				</span>
			</div>
		</div>
	);	
}
