import { TopMenu } from 'components/common/menus/TopMenu';

export function MainContent({ title="", children="" }) {
	return(
		<div className="main-content lg:pl-80 flex flex-col">

			<TopMenu />

			<main className="flex-1">
				<div className="py-6">
					{title !== "" &&
					<div className="mx-w-7xl mx-auto px-4 sm:px-6 md:px-8">
						<h1 className="text-2xl font-semibold text-gray-900">{title}</h1>
					</div>
					}
				
					<div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
						{children}
					</div>
				</div>            
			</main>
		</div>
	);
}