import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { LoadingSpinner } from 'components/common/LoadingSpinner';
import { PurchaseOrderDetailsHeader } from 'components/receive-po/PurchaseOrderDetailsHeader';
import { PurchaseOrderLineItemCard } from 'components/receive-po/PurchaseOrderLineItemCard';
import { usePoStore } from 'stores/po-store';

export function PurchaseOrderDetailsScreen() {
	let { poNumber } = useParams();
	const po = usePoStore(useCallback((state) => state.pos.find(x => x.po_number == poNumber), [poNumber]));
	const isInitialized = usePoStore((state) => state.isInitialized);
	const isLoading = usePoStore((state) => state.isLoading);
	const hasError = usePoStore((state) => state.hasError);
	const errorMessage = usePoStore((state) => state.errorMessage);

	

	if (!isInitialized || isLoading) {
		return (
			<div className="flex justify-center items-centered h-screen">
				<LoadingSpinner spinnerColorClass='text-gray-800' textColorClass='text-gray-800' />
			</div>
		);
	}
	
	if (hasError) {
		return <span>Error: {errorMessage}</span>
	}	
	


	return(
		<>
			<PurchaseOrderDetailsHeader po={po} />
			
			<div className="grid grid-cols-1 gap-4 sm:grid-cols-1 xl:grid-cols-2">
				{po.line_items.map((lineItem) => {
					return <PurchaseOrderLineItemCard key={lineItem.key} lineItem={lineItem} />
				})}
			</div>
		</>
	);
}