import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';

import { LoadingSpinner } from 'components/common/LoadingSpinner';
import { HVACDetailsHeader } from 'components/pull-tracker/HVACDetailsHeader';
import { PullTrackerDetailsHeader } from 'components/pull-tracker/PullTrackerDetailsHeader';
import { JobItemCard } from 'components/pull-tracker/JobItemCard';
import { JobEquipmentCard } from 'components/pull-tracker/JobEquipmentCard';
import { useJobStore } from 'stores/job-store';


export function PullTrackerDetailsScreen() {
	let { jobNumber } = useParams();
	const job = useJobStore(useCallback((state) => state.jobs.find(x => x.job_number == jobNumber), [jobNumber]));
	const isInitialized = useJobStore((state) => state.isInitialized);
	const isLoading = useJobStore((state) => state.isLoading);
	const hasError = useJobStore((state) => state.hasError);
	const errorMessage = useJobStore((state) => state.errorMessage);
	
	if (!isInitialized || isLoading) {
		return (
			<div className="flex justify-center items-centered h-screen">
				<LoadingSpinner spinnerColorClass='text-gray-800' textColorClass='text-gray-800' />
			</div>
		);
	}
	
	if (hasError) {
		return <span>Error: {errorMessage}</span>
	}
	


	return(
		<>
			<PullTrackerDetailsHeader job={job} />
			
			{job.hasStages() > 0 &&
				<>
					<HVACDetailsHeader stage={job.activeStage()} />
					
					<div className="grid grid-cols-1 gap-4 sm:grid-cols-2 xl:grid-cols-3">
						{
							_.map(_.orderBy(job.activeStage().equipments, 'system_number'), jobEquipment => {
								return <JobEquipmentCard
								key={jobEquipment.key} jobEquipment={jobEquipment} />
							})
						}
					</div>
				</>
			}
			
			{job.hasItems() &&
				<div className="w-full border-t border-gray-300 my-8" />
			}
			
			{job.hasItems() &&
				<div className="grid grid-cols-1 gap-4 sm:grid-cols-2 xl:grid-cols-3">
					{job.items.map((jobItem) => {
						return <JobItemCard key={jobItem.key} jobItem={jobItem} />
					})}
				</div>
			}
		</>
	);
}







