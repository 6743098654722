import _ from 'lodash';
import { createStore } from 'state';

import { inventory as api } from 'services/api';
import { InventoryItem } from 'models/InventoryItem';
import { WarehouseCount } from 'models/WarehouseCount';

let state = (set, get) => ({
	isLoading: false,
	isUpdating: false,
	itemBeingUpdated: null,
	hasError: false,
	errorMessage: "",
	showUncountedItems: true,
	showStartedItems: true,
	showCompletedItems: true,
	
	inventoryItems: [],
	
	setError: async (message) => {
		set((state) => { 
			state.hasError = true;
			state.errorMessage = message;
		});
	},

	clearError: async () => {
		set((state) => {
			state.hasError = false;
			state.errorMessage = "";
		});
	},
	
	
	
	setShowUncountedItems: (value) => {
		set((state) => {
			state.showUncountedItems = value;
		});
	},
	
	setShowStartedItems: (value) => {
		set((state) => {
			state.showStartedItems = value;
		});
	},
	
	setShowCompletedItems: (value) => {
		set((state) => {
			state.showCompletedItems = value;
		});
	},
	
	
	getAllInventoryItems: async () => {
		set((state) => void(state.isLoading = true));
		
		let result = await api.getAllInventoryItems();
		let items = result.map(record => new InventoryItem(record));
		
		set((state) => {
			state.inventoryItems = items;
			
			state.isLoading = false;
		});
	},
	
	getInventoryItems: async (binId) => {
		set((state) => void(state.isLoading = true));
		
		let result = await api.getInventoryItems(binId);
		let items = result.map(record => new InventoryItem(record));
		
		set((state) => {
			state.inventoryItems = _.unionWith(items, state.inventoryItems, (a, b) => {
				return (a.bin_id == b.bin_id) && (a.part_number == b.part_number);
			});
			
			state.isLoading = false;
		});
	},
	
	updateCount: async (binId, partNumber, payload) => {
		set((state) => {
			state.itemBeingUpdated = partNumber;
		});
		
		let record = await api.updateWarehouseCount(binId, partNumber, payload);
		let updatedWarehouseCount = new WarehouseCount(record);
		
		set((state) => {
			let item = state.inventoryItems.find(x => x.part_number == partNumber);
			item.counts[updatedWarehouseCount.location_code] = updatedWarehouseCount;
			
			state.itemBeingUpdated = null;
		});
	},
	
	updateStatus: async (binId, partNumber, payload) => {
		set((state) => {
			state.itemBeingUpdated = partNumber;
		});
		
		let record = await api.updateInventoryItemStatus(binId, partNumber, payload);
		let updatedInventoryItem = new InventoryItem(record);
		
		set((state) => {
			let itemIndex = state.inventoryItems.findIndex(x => x.part_number == partNumber);
			state.inventoryItems.splice(itemIndex, 1, updatedInventoryItem);
			
			state.itemBeingUpdated = null;
		});
	}
});

const useInventoryItemStore = createStore(state);

export {
	useInventoryItemStore
}