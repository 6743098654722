import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { AppLayout } from 'components/common/layouts';
import { Sidebar } from 'components/common/Sidebar';
import { MainContent } from 'components/common/MainContent';
import { SettingsSidebarContent } from 'components/settings/SettingsSidebarContent';
import { useSettingStore } from 'stores';

export function SettingsScreen() {
	const isInitialized = useSettingStore(state => state.isInitialized);
	const initializeStore = useSettingStore(state => state.initializeStore);
	
	useEffect(() => {
		if (!isInitialized) {
			initializeStore();
		}
	}, []);



	return(
		<AppLayout>
			<Sidebar title="Sections">
				<SettingsSidebarContent />
			</Sidebar>
			
			<MainContent>
				<Outlet />
			</MainContent>
		</AppLayout>
	);
}