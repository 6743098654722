import _ from 'lodash';
import { immerable } from 'immer';
import { DateTime } from 'luxon';

export class JobItem
{
	[immerable] = true;

	_original;
	_pulled_on;
	
	job_number;
	item;
	description;
	manufacturer;
	manufacturer_part_number;
	status;
	stage;
	bin;
	quantity_requested;
	quantity_shipped;
	quantity_outstanding;
	
	get key() {
		return `key_${this.job_number}_${this.item}`;
	}

	get pulled_on() {
		return this._pulled_on;
	}
	set pulled_on(value) {
		if (value instanceof Date) {
			this._pulled_on = DateTime.fromJSDate(value);
		}

		if (value instanceof DateTime) {
			this._pulled_on = value;
		}

		// Luxon can't format arbitrarily formatted strings, so this will produce
		// 'Invalid DateTime' as the string output if the input is invalid
		// We'll just assume it's an ISO date string, so that we at least always
		// have a Luxon DateTime here
		this._pulled_on = DateTime.fromISO(value);
	}
	
	constructor(data)
	{
		this._original = data;
		
		this.job_number = data.job_number;
		this.item = data.item;
		this.description = data.description;
		this.manufacturer = data.manufacturer;
		this.manufacturer_part_number = data.manufacturer_part_number;
		this.status = data.status;
		this.stage = data.stage;
		this.bin = data.bin;
		this.pulled_on = data.pulled_on;
		this.quantity_requested = data.quantity_requested;
		this.quantity_shipped = data.quantity_shipped;
		this.quantity_outstanding = data.quantity_outstanding;
	}
	
	isUnprocessed() {
		return this.quantity_shipped === 0;
	}
	
	isInProcess() {
		return this.quantity_shipped > 0 && this.quantity_shipped < this.quantity_requested;
	}
	
	isComplete() {
		return this.quantity_shipped == this.quantity_requested;
	}

	isDirty() {
		return (parseInt(this._original.quantity_outstanding) != parseInt(this.quantity_outstanding));
	}
	
	toPlain() {
		let output = {
			job_number: this.job_number,
			item: this.item,
			description: this.description,
			manufacturer: this.manufacturer,
			manufacturer_part_number: this.manufacturer_part_number,
			status: this.status,
			stage: this.stage, 
			bin: this.bin,
			pulled_on: this.pulled_on, 
			quantity_outstanding: parseInt(this.quantity_outstanding),
			quantity_requested: parseInt(this.quantity_requested),
			quantity_shipped: parseInt(this.quantity_shipped)
		}

		return output;
	}
}