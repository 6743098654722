import _ from 'lodash';
import { createStore } from 'state';

import { settings as api } from 'services/api';

let state = (set, get) => ({
	isInitialized: false,
	isLoading: false,
	isSaving: false,
	
	settings: {},
	
	initializeStore: async () => {
		set(state => {
			state.isInitialized = false;
			state.isLoading = true;
			state.isSaving = false;
		});
		
		await get().getSettings();
		
		set(state => void(state.isInitialized = true));
	},
	
	getSettings: async () => {
		set(state => {
			state.isLoading = true;
		});
		
		let result = await api.getSettings();
		
		set(state => {
			state.settings = _.merge(state.settings, result);
			
			state.isLoading = false;
			
			return state;
		});
	},
	
	updateSetting: async (companyId, settingName, value) => {
		set(state => {
			state.settings[companyId][settingName] = value;
		});
	},
		
	saveSettings: async (companyId) => {
		set(state => {
			state.isSaving = true;
		});
		
		let result = await api.saveSettings(companyId, get().settings[companyId]);
		
		set(state => {
			state.settings[companyId] = result;
			
			state.isSaving = false;
		});
	}		
});

const useSettingStore = createStore(state);

export {
	useSettingStore
}	
		