import { immerable } from 'immer';

export class PurchaseOrderLineItem
{
	[immerable] = true;
	
	_original;
	
	po_number;
	item;
	description;
	manufacturer;
	manufacturer_part_number;
	quantity_ordered;
	quantity_received;
	last_received_on;
	quantity_outstanding;

	quantity_to_add;
	
	get key() {
		return 'key_' + this.po_number + '_' + this.item.toLowerCase();
	}
	
	constructor(data)
	{
		this._original = data;
		
		this.po_number = data.po_number;
		this.item = data.item;
		this.description = data.description;
		this.manufacturer = data.manufacturer;
		this.manufacturer_part_number = data.manufacturer_part_number;
		this.quantity_ordered = data.quantity_ordered;
		this.quantity_received = data.quantity_received;
		this.last_received_on = data.last_received_on;
		this.quantity_outstanding = data.quantity_outstanding;
		
		this.quantity_to_add = this.quantity_outstanding;
	}

	isDirty() {
		return (this._original.quantity_outstanding != parseInt(this.quantity_to_add));
	}
	
	toPlain() {
		let output = {
			po_number: this.po_number,
			item: this.item,
			description: this.description,
			manufacturer: this.manufacturer,
			manufacturer_part_number: this.manufacturer_part_number,
			quantity_ordered: this.quantity_ordered,
			quantity_received: this.quantity_received + parseInt(this.quantity_to_add),
			last_received_on: this.last_received_on
		}

		return output;
	}
}
