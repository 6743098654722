import { immerable } from 'immer';

export class WarehouseLocation
{
	[immerable] = true;
	
	_original;
	
	code;
	description;
	
	constructor(data) {
		this._original = data;
		
		this.code = data.code;
		this.description = data.description;
	}
}