import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { AuthService } from 'services/AuthService';
import { Button } from 'components/common/buttons/Button';
import { TextInput, TextInputLabel } from 'components/common/forms/TextInput';

export default function LoginScreen() {
	const navigate = useNavigate();
	
	const [email, setEmail] = useState("");
	const [emailErrorMessage, setEmailErrorMessage] = useState("");
	const [password, setPassword] = useState("");
	const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
	
	const login = async () => {
		if (!email) {
			setEmailErrorMessage('Username is required');
		}
		
		if (!password) {
			setPasswordErrorMessage('Password is required');
		}
		
		let response = await AuthService.login(email, password);
		
		// if I don't do this then context isn't updated by the time the nav happens
		// and I just get bounced back to login
		// there's probably a better way to do this that fixes that
		setTimeout(() => {
			navigate('/dashboard', { replace: true });
		}, 0);
	}	
	
	return(
		<>
			<div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
				<div className="sm:mx-auto sm:w-full sm:max-w-md">
					<img
						className="mx-auto h-12 w-auto"
						src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
						alt="Workflow"
					/>
					<h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Chronos</h2>
				</div>

				<div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
					<div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
						<div className="space-y-6">
							<div>
								<TextInputLabel htmlFor="email">Username</TextInputLabel>
								<TextInput id="email" type="email" autoComplete="email" required value={email} changeHandler={setEmail} errorMessage={emailErrorMessage} />
							</div>
							
							<div>
								<TextInputLabel htmlFor="password">Password</TextInputLabel>
								<TextInput id="password" type="password" autoComplete="current-password" required value={password} changeHandler={setPassword} errorMessage={passwordErrorMessage} />
							</div>

							<div className="flex items-center justify-between">
								<div className="flex items-center">
									<input
										id="remember-me"
										name="remember-me"
										type="checkbox"
										className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
									/>
									<label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
										Remember me
									</label>
								</div>

								<div className="text-sm">
									<a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
										Forgot your password?
									</a>
								</div>
							</div>

							<div>
								<Button onClick={login}>Log in</Button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}