import { Fragment, useContext } from 'react'
import { NavLink, useNavigate } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react'
import { AppContext } from 'contexts/App';
import { CogIcon, UserIcon, XIcon } from '@heroicons/react/outline'
import { CompanySelector } from 'components/common/menus/CompanySelector';
import { classNames } from 'helpers';
import { AuthService } from 'services/AuthService';

function MobileMenuItem({ item, mobile=false, menuToggler }) {
	const app = useContext(AppContext);
	
	return(
		<NavLink
			to={item.to}
			className={({ isActive }) => {
				return classNames(
					isActive ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
					`px-3 py-2 my-2 rounded-md text-sm font-medium ${mobile} ? block : ''`
				)
			}}
			onClick={() => {
				// If this is a mobile size, then we need to open the sidebar when we navigate to a page, or it's just blank
				if (window.innerWidth < 768) {
					setTimeout(() => app.setSidebarOpen(true), 10);
				}
				
				// Then close the menu, regardless of screen size
				menuToggler(false);
			}}
		>
			{item.label}
		</NavLink>
	);
}




export function MobileMenu({ title="", children }) {
	const app = useContext(AppContext);
	const navigate = useNavigate();
	
	const MENU_ITEMS = [
		{ id: 'dashboard', label: 'Dashboard', to: '/dashboard' },
		{ id: 'pull-tracker', label: 'Pull Tracker', to: '/pull-tracker' },
		{ id: 'inventory', label: 'Inventory', to: '/inventory/bins' },
		{ id: 'receive-po', label: 'Receive PO', to: '/receive-po' }
	];
	
	const signOut = () => {
		AuthService.logout().then(() => navigate('/login'));
	}
	
	const goToSettings = () => {
		app.setMobileMenuOpen(false);
		setTimeout(() => app.setSidebarOpen(true), 10);
		navigate('/settings');
	}
	
	return (
		<div className="mobile-menu">
			{/* Desktop version (doesn't open and close) */}
			<div className="hidden lg:flex md:w-80 md:flex-col md:fixed md:inset-y-0">
				<div className="flex-1 flex flex-col min-h-0 bg-gray-800">

					{/* Logo */}
					<div className="flex items-center h-16 flex-shrink-0 px-4 bg-gray-800 border-b border-gray-700">
						<CompanySelector />
					</div>
					
					{/* Search and List */}
					<div className="mt-5 flex-1 pb-4 px-4 h-0 overflow-y-auto">
						{title !== "" &&
						<div className="text-center mb-4">
							<h1 className="text-2xl font-semibold text-white">{title}</h1>
						</div>
						}
						
						{children}
					</div>
				</div>
			</div>
		
			{/* Mobile version (opens and closes) */}
			<Transition.Root show={app.mobileMenuOpen} as={Fragment}>
				<Dialog as="div" className="relative z-40 lg:hidden" onClose={app.setMobileMenuOpen}>
					<Transition.Child
						as={Fragment}
						enter="transition-opacity ease-linear duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="transition-opacity ease-linear duration-300"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
					</Transition.Child>

					<div className="fixed inset-0 flex z-40">
						<div className="flex-shrink-0 w-14 ml-auto" aria-hidden="true">
							{/* Dummy element to force sidebar to shrink to fit close icon */}
						</div>
						
						<Transition.Child
							as={Fragment}
							enter="transition ease-in-out duration-300 transform"
							enterFrom="translate-x-full"
							enterTo="-translate-x-0"
							leave="transition ease-in-out duration-300 transform"
							leaveFrom="-translate-x-0"
							leaveTo="translate-x-full"
						>
							<Dialog.Panel className="relative flex-1 flex flex-col max-w-xs w-full bg-gray-800">
								<Transition.Child
									as={Fragment}
									enter="ease-in-out duration-300"
									enterFrom="opacity-0"
									enterTo="opacity-100"
									leave="ease-in-out duration-300"
									leaveFrom="opacity-100"
									leaveTo="opacity-0"
								>
									<div className="absolute top-0 left-0 -ml-12 mt-1 pt-2">
										<button
											type="button"
											className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
											onClick={() => app.setMobileMenuOpen(false)}
										>
											<span className="sr-only">Close sidebar</span>
											<XIcon className="h-6 w-6 text-white" aria-hidden="true" />
										</button>
									</div>
								</Transition.Child>
								
								{/* Logo */}
								<div className="flex items-center h-16 flex-shrink-0 px-4 bg-gray-800 border-b border-gray-700">
									<CompanySelector />
								</div>
								
								{/* Search and List */}
								<div className="mt-5 flex-1 pb-4 px-4 h-0 overflow-y-auto">
									{MENU_ITEMS.map(item => (
										<MobileMenuItem key={item.id} item={item} menuToggler={app.setMobileMenuOpen} />
									))}
								</div>	

								<div className="flex flex-shrink-0 p-4 mt-auto cursor-pointer hover:bg-gray-700">
									<div className="group block flex-shrink-0">
										<div className="flex items-center justify-center">
											<div className="flex items-center">
												<CogIcon className="inline-block h-5 w-5 rounded-full text-white" />
											</div>
											<div className="ml-3">
												<p className="text-sm text-white group-hover:text-white" onClick={goToSettings}>Settings</p>
											</div>
										</div>
									</div>
								</div>								
									
								<div className="flex flex-shrink-0 border-t border-gray-700 p-4 mt-auto cursor-pointer hover:bg-gray-700">
									<div className="group block flex-shrink-0">
										<div className="flex items-center">
											<div>
												<UserIcon className="inline-block h-6 w-6 rounded-full text-white" />
											</div>
											<div className="ml-3">
												<p className="text-base font-medium text-white group-hover:text-white" onClick={signOut}>Sign Out</p>
											</div>
										</div>
									</div>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition.Root>
		</div>
	)
}