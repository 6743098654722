import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { SidebarSearch } from 'components/common/SidebarSearch';
import { LoadingSpinner } from 'components/common/LoadingSpinner';
import { useSettingStore } from 'stores';
import { AppContext } from 'contexts/App';
import { companies } from 'companies';

export function SettingsSidebarContent() {
	const nav = useNavigate();
	const app = useContext(AppContext);
	const isInitialized = useSettingStore(state => state.isInitialized);
	const isLoading = useSettingStore(state => state.isLoading);
	
	if (!isInitialized || isLoading) {
		return <LoadingSpinner />
	}
	
	return(
		<SidebarSearch>
			<SidebarSearch.List>
				<SidebarSearch.Item
					title="Global Settings"
					onClick={() => {
						app.setSidebarOpen(false);
						nav('/settings/global')
					}}
				/>
				
				{_.map(companies, company => {
					return <SidebarSearch.Item
						key={company.id}
						title={company.name}
						onClick={() => {
							app.setSidebarOpen(false);
							nav(`/settings/company/${company.id}`)
						}}
					/>
				})}
			</SidebarSearch.List>
		</SidebarSearch>
	);
}