import { useEffect } from 'react';
import { TextInput } from 'components/common/forms/TextInput';
import { classNames } from 'helpers';
import { useJobStore } from 'stores/job-store';

export function JobItemCard({ jobItem }) {
	const hideCompletedItems = useJobStore((state) => state.hideCompletedItems);
	const isUpdating = useJobStore((state) => state.isUpdating);
	const updateQuantityOutstanding = useJobStore((state) => state.updateQuantityOutstanding);
	
	const updateQuantity = (value) => {
		updateQuantityOutstanding(jobItem, value);
	};

	let isHidden = (hideCompletedItems) ? jobItem.isComplete() : false;

	let inputClasses = 'appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm text-center font-bold';
	
	let totalPulledClasses = classNames(
		jobItem.quantity_requested == jobItem.quantity_shipped ? 'text-green-600' : 'text-red-600',
		'flex w-10 h-10 mx-auto items-center justify-center font-bold'
	);

	let quantityRequestedClasses = classNames(
		jobItem.quantity_requested == jobItem.quantity_shipped ? 'text-green-600' : 'text-red-600',
		'flex w-10 h-10 mx-auto items-center justify-center font-bold'
	)


	return(
		<div className={`relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex flex-col items-start space-x-3 hover:border-gray-400 ${isHidden ? 'hidden' : 'visible'}`}>
			<div className="flex flex-col flex-grow self-start mb-6">
				<div className="text-sm font-medium text-gray-900">{jobItem.item}</div>
				<div className="text-xs text-gray-500">{jobItem.manufacturer_part_number}</div>
				<div className="text-xs text-gray-500">{jobItem.description}</div>
			</div>
			
			<div className="flex flex-row">
				<div className="flex-shrink w-16 text-center">
					<div className="text-sm font-medium text-gray-900"># TO<br/>PULL</div>
					<div className="w-16 h-10 mx-auto">
						<TextInput 
							id={jobItem.key} 
							type="text" 
							value={jobItem.quantity_outstanding} 
							changeHandler={updateQuantity} 
							className={inputClasses} 
							onFocus={(e) => e.target.select()} 
							disabled={isUpdating} 
						/>
					</div>
				</div>
				
				<div className="flex-shrink w-16 text-center ml-4">
					<div className="text-sm font-medium text-gray-900">TOTAL<br/>PULLED</div>
					<div className="w-16 h-10 mx-auto">
						<div className={totalPulledClasses}>{jobItem.quantity_shipped}</div>
					</div>
				</div>
				
				<div className="flex-shrink w-16 text-center ml-4">
					<div className="text-sm font-medium text-gray-900"> <br/>REQ</div>
					<div className={quantityRequestedClasses}>{jobItem.quantity_requested}</div>
				</div>
			</div>
		</div>
	);
}